import { Injectable } from '@angular/core';
import { AppConfigProvider, ConfigurationTypeEnum } from '@bolt/ui-shared/configuration';
import { MasterDataCacheService } from '@bolt/ui-shared/master-data';
import { BehaviorSubject, Observable, Observer, of } from 'rxjs';

import { UserInterface } from 'app/modules/user/models/user.model';


export interface OAuth2TokenInterface {
  access_token: string;
}


@Injectable({
  providedIn: 'root',
})
export class AuthHelper {
  tokenName: string;
  attemptedUrlStorageKey: string = 'attempted-url';
  isAuthenticatedStream: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authenticatedUserStream: BehaviorSubject<UserInterface> = new BehaviorSubject(undefined);
  redirectUrl: string;
  authEnabled: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected cacheService: MasterDataCacheService
  ) {
    this.authEnabled = this.appConfig.get('auth.enabled', true, ConfigurationTypeEnum.boolean);
    this.tokenName = this.appConfig.get('auth.oAuth2.tokenName', 'oauth2-token');

    if (this.authEnabled) {
      this.isAuthenticatedStream.next(!!this.getTokenObject());
    } else {
      this.isAuthenticatedStream.next(true);
    }

    this.authenticatedUserStream.next(undefined);
  }

  /**
   * Returns the URL where the user logs in.
   *
   * @returns string
   */
  getLoginUrl(): string {
    const boltBaseUrl = [window.location.protocol, '//', window.location.host, '/'].join('');

    const url: string = this.appConfig.get('auth.loginUrl', '').toString().replace(
      '{callbackUrl}',
      encodeURIComponent(
        this.appConfig.get('auth.callbackUrl', '').toString().replace('{boltBaseUrl}', boltBaseUrl)
      )
    ).replace(
      '{samlLoginUrl}',
      encodeURIComponent(
        this.appConfig.get('auth.samlLoginUrl', '').toString().replace('{boltBaseUrl}', boltBaseUrl)
      )
    );

    return url;
  }

  /**
   * Returns the URL for Bolt support page.
   *
   * @returns string
   */
  getSupportPageUrl(): string {
    return this.appConfig.get('auth.boltSupport');
  }

  /**
   * Logout the logged user.
   *
   * @param broadcast boolean
   * @param sessionExpired boolean
   * @returns void
   */
  logout(broadcast: boolean = true, sessionExpired: boolean = false): void {
    sessionStorage.clear();
    this.cacheService.clear();

    if (broadcast) {
      localStorage.setItem('bolt-broadcast-logout', '');
      localStorage.removeItem('bolt-broadcast-logout');
    }

    if (sessionExpired) {
      localStorage.setItem('bolt-auth-session-expired', 'true');
    }

    this.isAuthenticatedStream.next(false);
    this.authenticatedUserStream.next(undefined);
  }

  /**
   * Indicates if it is authenticated.
   *
   * @returns Observable<boolean>
   */
  isAuthenticated(): Observable<boolean> {
    return of(!!this.getTokenObject());
  }

  /**
   * Indicates if the session is expired.
   *
   * @returns boolean
   */
  expiredSession(): boolean {
    return !!JSON.parse(localStorage.getItem('bolt-auth-session-expired'));
  }

  /**
   * Returns the Token object.
   *
   * @returns OAuth2TokenInterface
   */
  getTokenObject(): OAuth2TokenInterface {
    return JSON.parse(sessionStorage.getItem(this.tokenName));
  }

  /**
   * Stores the Token object.
   *
   * @param oAuth2Token OAuth2TokenInterface
   * @returns void
   */
  storeToken(oAuth2Token: OAuth2TokenInterface, broadcast: boolean = true): void {
    sessionStorage.setItem(this.tokenName, JSON.stringify(oAuth2Token));

    if (broadcast) {
      localStorage.setItem('bolt-broadcast-authenticated', this.getToken());
      localStorage.removeItem('bolt-broadcast-authenticated');
    }

    this.isAuthenticatedStream.next(!!this.getTokenObject());
  }

  /**
   * Returns the Token value.
   *
   * @returns string
   */
  getToken(): string {
    const token: OAuth2TokenInterface = this.getTokenObject();

    if (token && token.access_token) {
      return token.access_token;
    } else {
      return undefined;
    }
  }

  /**
   * Sets the application URL the user attempted to access while being unauthenticated.
   *
   * @param url string
   * @returns void
   */
  setAttemptedUrl(url: string): void {
    sessionStorage.setItem(this.attemptedUrlStorageKey, JSON.stringify(url));
  }

  /**
   * Removes the application URL the user attempted to access while being unauthenticated.
   *
   * @returns void
   */
  unsetAttemptedUrl(): void {
    sessionStorage.removeItem(this.attemptedUrlStorageKey);
  }

  /**
   * Returns the application URL the user attempted to access while being unauthenticated.
   *
   * @returns string
   */
  getAttemptedUrl(): string {
    return JSON.parse(sessionStorage.getItem(this.attemptedUrlStorageKey)) || undefined;
  }

  /**
   * Returns the Authorization Header's name and value.
   *
   * @returns string[]
   */
  getAuthorizationHeader(): string[] {
    const headers: string[] = [
      this.appConfig.get('auth.oAuth2.headerName', 'Authorization'),
      this.appConfig.get('auth.oAuth2.headerPrefix', 'BEARER') + ' ' + this.getToken()
    ];

    return headers;
  }

  /**
   * Set the authenticated user.
   *
   * @param user UserInterface
   * @returns void
   */
  setAuthenticatedUser(user: UserInterface): void {
    localStorage.removeItem('bolt-auth-session-expired');
    this.authenticatedUserStream.next(user);
  }

  /**
   * Returns the token object as an observable.
   *
   * @returns Observable<boolean>
   */
  getTokenObjectAsObservable(): Observable<boolean> {
    const obs: Observable<boolean> = Observable.create(
      (observer: Observer<any>) => {
        const token: OAuth2TokenInterface = this.getTokenObject();

        if (token) {
          observer.next(token);
          observer.complete();
        } else {
          observer.error(new Error('User not authenticated'));
        }
      }
    );

    return obs;
  }
}
