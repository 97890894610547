import { Component, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Country, Language } from '@bolt/ui-shared/master-data';
import { isObject as _isObject, isArray as _isArray } from 'lodash';
import { Observable, forkJoin } from 'rxjs';

import { CreditLocale } from '../../models/credit/credit-locale.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Title } from '../../../title/models/title.model';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditLockingStatusInterface } from 'app/modules/credits/models/credit/credit-locking-status.interface';
import { BoltCreditsManagerComponent } from '../bolt-credits-manager/bolt-credits-manager.component';


@Component({
  selector: 'bolt-credits-group',
  template: require('./bolt-credits-group.html'),
  styles: [require('./bolt-credits-group.scss')],
})
export class BoltCreditsGroupComponent extends StormComponent implements OnChanges {
  @Input() title: Title;
  @Input() creditType: CreditType;
  @Input() creditPositionType: CreditPositionType;

  @ViewChild('translatedCredits') translatedCredits: BoltCreditsManagerComponent;
  @ViewChild('originalCredits') originalCredits: BoltCreditsManagerComponent;

  protected creditLocaleUpdated: CreditLocale;
  protected creditPositionTypes = CreditPositionType;
  protected creditTypes = CreditType;
  protected defaultOriginalCreditLocale: CreditLocale;
  protected originalLockingStatus: boolean;
  protected shouldRefreshLocalizations: boolean;
  protected shouldRefreshLocalized: boolean;
  protected selectedCredit: CreditLocale;

  constructor(
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      _isObject(changes.title) &&
      _isObject(changes.title.currentValue)
    ) {
      this.setupDefaultOriginalCreditLocale();
    }
  }

  /**
   * Manages the change of the order in an original Cast or Crew
   *
   * @param credits: Credit[]
   * @returns void
   */
  updateOrder(credits: any): void {
    this.translatedCredits?.refreshOrder(credits);
    this.translatedCredits?.block();
  }

  /**
   * Get the language with the given id.
   *
   * @param id number
   * @returns Observable<Language>
   */
  protected getLanguageById(id: number): Observable<Language> {
    return this.masterDataManager.getLanguageById(id);
  }

  /**
   * Get the language with the given code.
   *
   * @param code string
   * @returns Observable<Language>
   */
  protected getLanguageByCode(code: string): Observable<Language> {
    return this.masterDataManager.getLanguageByCode(code);
  }

  /**
   * Get the territory with the given code.
   *
   * @param code string
   * @returns Country
   */
  protected getTerritoryByCode(code: string): Observable<Country> {
    return this.masterDataManager.getTerritoryByCode(code);
  }

  /**
   * Get the territory with the given id.
   *
   * @param id number
   * @returns Observable<Country>
   */
  protected getTerritoryById(id: number): Observable<Country> {
    return this.masterDataManager.getTerritoryById(id);
  }

  /**
   * Indicates if it has to display the original section.
   *
   * @returns boolean
   */
  protected hasDisplayOriginalSection(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      _isObject(this.title) &&
      _isObject(this.defaultOriginalCreditLocale);

    return hasIt;
  }

  /**
   * Perform the refresh process when the original locking status changes.
   *
   * @param creditLockingStatus CreditLockingStatusInterface
   * @returns void
   */
  protected updateOriginalLockingStatus(creditLockingStatus: CreditLockingStatusInterface): void {
    if (!creditLockingStatus.locked && this.creditType === CreditType.TRANSLATED) {
      this.refreshLocalizationListLockingStatus(true);
      this.refreshLocalized(true);
    }
  }

  /**
   * Sets the locking status for Original credit.
   *
   * @param status boolean
   * @returns void
   */
   protected setOriginalLockingStatus(status: boolean): void {
    this.originalLockingStatus = status;
  }

  /**
   * Refresh the localization list locking status.
   *
   * @param value boolean
   * @returns void
   */
  protected refreshLocalizationListLockingStatus(value: boolean): void {
    this.shouldRefreshLocalizations = value;

    setTimeout(() => {
      this.shouldRefreshLocalizations = false;
    }, 0);
  }

  /**
   * Refresh the localized section.
   *
   * @param value boolean
   * @returns void
   */
  protected refreshLocalized(value: boolean): void {
    this.shouldRefreshLocalized = value;

    setTimeout(() => {
      this.shouldRefreshLocalized = false;
    }, 0);
  }

  /**
   * Set the selected credit.
   *
   * @param credit CreditLocale
   * @returns void
   */
  protected setSelectedCredit(credit: CreditLocale): void {
    this.selectedCredit = credit;
  }

  /**
   * Set up the default credit locale for the original section.
   *
   * @returns void
   */
  protected setupDefaultOriginalCreditLocale(): void {
    this.changeStatusToFetchingData();

    let originalLanguageId: number;

    if (_isArray((<any>this.title).originalSpokenLanguageId)) {
      originalLanguageId = (<any>this.title).originalSpokenLanguageId[0];
    } else {
      originalLanguageId = (<any>this.title).originalSpokenLanguageId;
    }

    forkJoin([
      this.getLanguageById(originalLanguageId),
      this.getTerritoryById(Country.ALL_ID)
    ]).subscribe(
      (responses) => {
        const data: any = {
          language: responses[0],
          territory: [responses[1]]
        };

        this.defaultOriginalCreditLocale = new CreditLocale(data);
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the languages and territories', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Update the credit locale list with the given data.
   *
   * @param creditLockingStatus CreditLockingStatusInterface
   * @returns void
   */
  protected updateLocalizedCreditLockingStatus(creditLockingStatus: CreditLockingStatusInterface): void {
    const territoriesCodes: string = creditLockingStatus.locale.split('_')[1];
    const languageCode: string = creditLockingStatus.locale.split('_')[0];
    const observables: Array<Observable<Language | Country>> = new Array();

    observables.push(this.getLanguageByCode(languageCode));

    territoriesCodes.split(',').forEach(
      (code: string) => {
        observables.push(this.getTerritoryByCode(code));
      }
    );

    forkJoin(observables).subscribe(
      (responses: Country[]) => {
        const data: any = {
          language: responses[0],
          territory: responses.slice(1),
          locked: creditLockingStatus.locked
        };

        const creditLocale: CreditLocale = new CreditLocale(data);
        this.creditLocaleUpdated = creditLocale;
      }
    );
  }
}
