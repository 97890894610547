import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { HttpError } from '@bolt/ui-shared/common';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import {
  Account, Country, List, Rating, TypeEnum,
  RatingSystemType, RatingSystem, RatingSystemClientInterface, Language
} from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { LevelModeEnum } from '@bolt/ui-shared/title';
import { isArray as _isArray, isUndefined as _isUndefined, isObject as _isObject, isEmpty as _isEmpty } from 'lodash';
import { forkJoin, Observable } from 'rxjs';

import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { TerritoryLocalizationForm } from '../../models/localization/form/territory/territory-localization-form.model';
import { BoltTitleLocalizationFormComponent } from '../bolt-title-localization-form/bolt-title-localization-form.component';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { EpisodeAttributes } from '../../models/title/episode/episode-attributes.enum';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { BoltRatingService } from '../../services/bolt-rating.service';
import { TerritoryLocalizationWithRatingsForm } from '../../models/localization/form/territory/territory-localization-with-ratings-form.model';


@Component({
  selector: 'bolt-title-territory-localization-form',
  template: require('./bolt-title-territory-localization-form.component.html'),
  styles: [require('./bolt-title-territory-localization-form.scss')]
})
export class BoltTitleTerritoryLocalizationFormComponent extends BoltTitleLocalizationFormComponent
  implements AfterViewInit, OnInit, OnDestroy {
  @Input() creationMode: boolean;
  @Input() form: TerritoryLocalizationForm | TerritoryLocalizationWithRatingsForm;
  @Input() isCustomization: boolean;
  @Input() showUnifiedRatings: boolean = false;

  readonly levelMode: LevelModeEnum = LevelModeEnum.territoryAccount;

  protected ratings: SelectionItem[];
  protected ratingsSystemReason: SelectionItem[];
  protected filteredRatings: SelectionItem[];
  protected filteredRatingsSystemReason: SelectionItem[];
  protected filteredHomeEntertainmentRatingsSystemReason: SelectionItem[];
  protected loadingRatings: boolean;
  protected loadingRatingsSystemReason: boolean;
  protected loadingHomeEntertainmentRatingsSystemReason: boolean;
  protected ratingSystemTypes: typeof RatingSystemType = RatingSystemType;
  protected featureAttributes: typeof FeatureAttributes = FeatureAttributes;
  protected episodeAttributes: typeof EpisodeAttributes = EpisodeAttributes;
  protected activeTab: string = 'theatrical';
  protected isBoltRatingsEnable: boolean = false;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected appConfig: AppConfigProvider,
    protected cd: ChangeDetectorRef,
    protected listsProvider: StormListsProvider,
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService,
    protected boltRatingService: BoltRatingService
  ) {
    super(appConfigurationManager, listsProvider);
    this.filteredRatings = [];
    this.filteredRatingsSystemReason = [];
    this.filteredHomeEntertainmentRatingsSystemReason = [];
    this.loadingGenres = false;
    this.loadingRatings = false;
    this.loadingRatingsSystemReason = false;
    this.loadingHomeEntertainmentRatingsSystemReason = false;
  }

  ngOnInit() {
    this.loadLists();
    this.activateRatingsCheck();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.form.getSplitFunctionalMetadataFlag()) {
      this.cd.detectChanges();
    }
  }

  activateRatingsCheck()  {
    this.setIsBoltRatingsEnable();
    this.subscriptions.add(
      this.form.valueChanges.subscribe(() => {
        this.setIsBoltRatingsEnable();
      })
    );
  }

  /**
   * Determines if the new bolt ratings is enabled.
   *
   * @returns boolean
   */
  setIsBoltRatingsEnable(): boolean {
    this.isBoltRatingsEnable = this.checkAccountAndLanguageAll();

    return this.isBoltRatingsEnable;
  }

  hasAnyRatingAttribute() {
    const hasIt = this.hasAttribute(FeatureAttributes.ratingId)
      || this.hasAttribute(FeatureAttributes.ratingSystemReasonId)
      || this.hasAttribute(FeatureAttributes.ratingReason)
    ;

    return hasIt;
  }

  /**
   * Returns the label of the ratings input
   * @returns string
   */
  getRatingsLabel() {
    return this.isBoltRatingsEnable ? `${this.boltRatingService.getTheatricalSource(this.title)} Rating` : `Rating`;
  }

  /**
   * Applies the current filters after a territory or account change.
   *
   * @returns void
   */
  protected applyFilters(): void {
    this.filterGenres();
    this.filterRatings();
    this.setIsBoltRatingsEnable();
  }

  protected checkAccountAndLanguageAll() {
    const accountIds: number[] = this.form.getAccountField().value || [];
    const productTypeIds: number[] = this.form.getProductTypeField().value || [];
    const languageIds: number = this.form.getLanguageField().value || 0;

    const hasAccountAll: boolean = (accountIds.length === 1) && Account.isAll(accountIds[0]);
    const hasProductTypeAll: boolean = (productTypeIds.length === 1) && Language.isAll(productTypeIds[0]);
    const hasLanguageAll: boolean = Language.isAll(languageIds);

    const hasIt = hasAccountAll && hasProductTypeAll && hasLanguageAll;

    return hasIt;
  }

  /**
   * Indicates if the account is Disney SVOD.
   *
   * @returns boolean
   */
  protected hasDisneySvodAccount(): boolean {
    const accountId: number[] = _isArray(this.form.getAccountField().value) ? this.form.getAccountField().value : [];
    const hasIt: boolean = (accountId.length === 1) && Account.isDisneySvod(accountId[0]);

    return hasIt;
  }

  /**
   * Filters the current genres.
   *
   * @param reset boolean
   * @returns void
   */
  protected filterGenres(reset: boolean = true): void {
    if (this.hasAttribute(FeatureAttributes.genreId)) {
      this.loadingGenres = true;
      this.filteredGenres = [];
      const accountId: number[] = _isArray(this.form.getAccountField().value) ? this.form.getAccountField().value : [];
      const territoryId: number[] = _isArray(this.form.getTerritoryField().value) ? this.form.getTerritoryField().value : [];

      if (reset) {
        this.form.getGenreIdField().setValue(undefined);
      }

      const hasOneAccountSelected: boolean = accountId.length === 1;
      const hasOneTerritorySelected: boolean = territoryId.length === 1;

      // Filter by ACCOUNT_TERRITORY Client-Specific
      if (
        hasOneAccountSelected &&
        !Account.isAll(accountId[0]) &&
        hasOneTerritorySelected &&
        !Country.isAll(territoryId[0])
      ) {
        this.filterAccountTerritoryGenres(territoryId[0], accountId[0]);

      } else if (
          hasOneAccountSelected &&
          !Account.isAll(accountId[0]) &&
          (!hasOneTerritorySelected || Country.isAll(territoryId[0]))
        ) {
          this.filterAccountGenres(accountId[0]);

      } else {
        this.getFilteredGenresByType();
      }
    }
  }

  /**
   * Filters the current ratings with the current territory value.
   *
   * @param reset boolean
   * @returns void
   */
  protected filterRatings(reset: boolean = true): void {
    if (this.hasAttribute(FeatureAttributes.ratingId)) {
      this.loadingRatings = true;
      this.filteredRatings = [];
      let selectedAccount: Account;
      const accountId: number[] = _isArray(this.form.getAccountField().value) ? this.form.getAccountField().value : [];
      const territoryId: number[] = _isArray(this.form.getTerritoryField().value) ? this.form.getTerritoryField().value : [];

      if (reset) {
        this.form.getRatingIdField().setValue(undefined);
      }

      // Business rules:
      // Filter Client-specific Ratings when there is one territory and one account (excluding all).
      // Filter Official Ratings when multiple territories and/or accounts were selected or there aren't any Client-Specific Ratings.
      if (
        (accountId.length === 1 && !Account.isAll(accountId[0])) &&
        (territoryId.length === 1 && !Country.isAll(territoryId[0]))
      ) {
        this.subscriptions.add(
          this.masterDataManager.getAccountById(accountId[0]).subscribe(
            (account: Account) => {
              selectedAccount = account;

              this.filterClientSpecificRatings(territoryId[0], selectedAccount);

              if (this.filteredRatings.length === 0) {
                this.filterOfficialRatings(territoryId);
              }
            },
            (error: HttpError) => {
              this.notificationService.handleError('Failed trying to fetch an Account', error);
            }
          )
        );
      } else {
        this.filterOfficialRatings(territoryId);
      }

      this.loadingRatings = false;
    }
  }

  /**
   * Filters the current ratings system reason with the given rating value.
   *
   * @param reset boolean
   */
  protected filterRatingsSystemReason(reset: boolean = true): void {
    if (this.hasAttribute(FeatureAttributes.ratingSystemReasonId)) {
      let ratingId: any[];

      if (!_isUndefined(this.form.getRatingIdField())) {
        ratingId = this.form.getRatingIdField().value;
      } else {
        ratingId = this.title[FeatureAttributes.ratingId];
      }

      if (_isArray(ratingId) && ratingId.every((rating: any) => _isObject(rating))) {
        ratingId = ratingId.map((rating: Rating) => rating.id);
      }

      this.loadingRatingsSystemReason = true;
      this.filteredRatingsSystemReason = [];

      if (reset) {
        this.form.getRatingSystemReasonIdField().setValue(undefined);
      }

      let ratingSystemId: number[] = [];

      if (_isArray(ratingId) && ratingId.length > 0) {
        ratingSystemId = this.ratings.filter(
          (rating: SelectionItem) => ratingId.includes(rating.source.id)
        ).map(
          (rating: SelectionItem) => rating.source.ratingSystemId
        );
      }

      this.filteredRatingsSystemReason = this.ratingsSystemReason.filter(
        (ratingSystemReason: SelectionItem) => {
          return ratingSystemReason.source.ratingSystems.some((ratingSystem: RatingSystem) => ratingSystemId.includes(ratingSystem.id));
        }
      ).map(
        (ratingSystemReason: SelectionItem) => new SelectionItem(
          ratingSystemReason.source.name,
          ratingSystemReason.source.id,
          ratingSystemReason.source,
          ratingSystemReason.source.ratingSystems.filter(
            (rs: RatingSystem) => ratingSystemId.includes(rs.id)
          ).map((rs: RatingSystem) => rs.name).join('\n')
        )
      );

      this.loadingRatingsSystemReason = false;
    }
  }

  /**
   * Filters the current Home Entertainment Ratings system reason with the given home entertainment rating value.
   *
   * @param reset boolean
   */
  protected filterHomeEntertainmentRatingsSystemReason(reset: boolean = true): void {
    if (this.hasAttribute(FeatureAttributes.homeEntRatingSystemReasonId)) {
      let ratingId: any[];
      const homeEntertainmentRatingIdControl = (this.form as TerritoryLocalizationWithRatingsForm).getHomeEntRatingIdField();

      if (!_isUndefined(homeEntertainmentRatingIdControl)) {
        ratingId = homeEntertainmentRatingIdControl.value;
      } else {
        ratingId = this.title[FeatureAttributes.homeEntRatingId];
      }

      if (_isArray(ratingId) && ratingId.every((rating: any) => _isObject(rating))) {
        ratingId = ratingId.map((rating: Rating) => rating.id);
      }

      this.loadingHomeEntertainmentRatingsSystemReason = true;
      this.filteredHomeEntertainmentRatingsSystemReason = [];

      if (reset) {
        (this.form as TerritoryLocalizationWithRatingsForm).getHomeEntRatingSystemReasonIdField().setValue(undefined);
      }

      let ratingSystemId: number[] = [];

      if (_isArray(ratingId) && ratingId.length > 0) {
        ratingSystemId = this.ratings.filter(
          (rating: SelectionItem) => ratingId.includes(rating.source.id)
        ).map(
          (rating: SelectionItem) => rating.source.ratingSystemId
        );
      }

      this.filteredHomeEntertainmentRatingsSystemReason = this.ratingsSystemReason.filter(
        (ratingSystemReason: SelectionItem) => {
          return ratingSystemReason.source.ratingSystems.some((ratingSystem: RatingSystem) => ratingSystemId.includes(ratingSystem.id));
        }
      ).map(
        (ratingSystemReason: SelectionItem) => new SelectionItem(
          ratingSystemReason.source.name,
          ratingSystemReason.source.id,
          ratingSystemReason.source,
          ratingSystemReason.source.ratingSystems.filter(
            (rs: RatingSystem) => ratingSystemId.includes(rs.id)
          ).map((rs: RatingSystem) => rs.name).join('\n')
        )
      );

      this.loadingHomeEntertainmentRatingsSystemReason = false;
    }
  }

  /**
   * Indicates if it has to display the South Korea fields.
   *
   * @returns boolean
   */
  protected hasDisplaySouthKoreaFields(): boolean {
    const territory: number[] = this.form.getTerritoryField().value;

    const hasIt: boolean =
      _isArray(territory) &&
      territory.length === 1 &&
      Country.isSouthKorea(territory[0]);

    return hasIt;
  }

  /**
   * Indicates if it has filtered ratings.
   *
   * @returns boolean
   */
  protected hasFilteredRatings(): boolean {
    return this.filteredRatings.length > 0;
  }

  /**
   * Indicates if it has filtered ratings system reasons.
   *
   * @returns boolean
   */
  protected hasFilteredRatingSystemReasons(): boolean {
    return this.filteredRatingsSystemReason.length > 0;
  }

  /**
   * Indicates if it has filtered ratings system reasons.
   *
   * @returns boolean
   */
  protected hasFilteredHomeEntertainmentRatingSystemReasons(): boolean {
    return this.filteredHomeEntertainmentRatingsSystemReason.length > 0;
  }

  /**
   * Indicates if it has a territory selected.
   *
   * @returns boolean
   */
  protected hasTerritorySelected(): boolean {
    const territory: number[] = this.form.getTerritoryField().value;
    const hasIt: boolean = _isArray(territory) && territory.length > 0;
    return hasIt;
  }

  /**
   * Indicates if it has a rating selected.
   *
   * @returns boolean
   */
  protected hasRatingSelected(): boolean {
    let rating: number[];

    if (!_isUndefined(this.form.getRatingIdField())) {
      rating = this.form.getRatingIdField().value;
    } else {
      rating = this.title[FeatureAttributes.ratingId];
    }

    const hasIt: boolean = _isArray(rating) && rating.length > 0;
    return hasIt;
  }

  protected loadLists(): void {
    this.loadFunctionalMetadataOptions();
    this.loadGenres();
    this.loadRatings();
    this.loadRatingsSystemReason();
  }

  /**
   * Filters client-specific ratings with the given territory and the account.
   *
   * @param territoryId number
   * @param account Account
   * @returns void
   */
  private filterClientSpecificRatings(territoryId: number, account: Account): void {
    this.filteredRatings = this.ratings.filter(
      (rating: SelectionItem) => {
        const isClientType: boolean = rating.source.ratingSystem.type === RatingSystemType.CLIENT;

        const matched: boolean = _isArray(rating.source.ratingSystem.clients) &&
          rating.source.ratingSystem.clients.some(
            (systemClient: RatingSystemClientInterface) => {
              return systemClient.territory.id === territoryId && systemClient.account.id === account.id;
            }
          );

        return isClientType && matched;
      }).map(
        (rating: SelectionItem) => {
          return new SelectionItem(
            rating.label,
            rating.value,
            rating.source,
            rating.source.ratingSystem.name + account.name
          );
        }
      );
  }

  /**
   * Filters client-specific genres with the given territory and account id.
   *
   * @param territoryId number
   * @param accountId number
   * @returns void
   */
  private filterAccountTerritoryGenres(territoryId: number, accountId: number): void {
    let selectedAccount: Account;
    let selectedTerritory: Country;
    const getAccountByIdObs: Observable<Account> = this.masterDataManager.getAccountById(accountId);
    const getTerritoryByIdObs: Observable<Country> = this.masterDataManager.getTerritoryById(territoryId);
    let accountTerritoryGenres: SelectionItem[] = [];
    let accountGenres: SelectionItem[] = [];

    this.subscriptions.add(
      forkJoin([getAccountByIdObs, getTerritoryByIdObs]).subscribe(
        ([account, territory]) => {
          selectedAccount = account;
          selectedTerritory = territory;

          accountTerritoryGenres = this.getFilteredGenresByAccountTerritory(
            account.id, territory.id
          ).map(
            (genre: SelectionItem) => {
              return new SelectionItem(
                genre.label,
                genre.value,
                genre.source,
               `${selectedAccount.name} - ${selectedTerritory.name}`
              );
            }
          );

          accountGenres = this.getFilteredGenresByAccount(account.id).map(
            (genre: SelectionItem) => {
              return new SelectionItem(
                genre.label,
                genre.value,
                genre.source,
                selectedAccount.name
              );
            }
          );

          if (accountTerritoryGenres.length !== 0) {
            this.filteredGenres = accountTerritoryGenres;
          } else if (accountGenres.length !== 0) {
            this.filteredGenres = accountGenres;
          } else {
            this.getFilteredGenresByType();
          }

          this.loadingGenres = false;
        },
        (error: HttpError) => {
          this.getFilteredGenresByType();
          this.loadingGenres = false;
          this.notificationService.handleError('Failed trying to fetch the localization items', error);
        }
      )
    );
  }

  /**
   * Filters account-specific genres with the given account id.
   *
   * @param accountId number
   * @returns void
   */
  private filterAccountGenres(accountId: number): void {
    let selectedAccount: Account;
    const getAccountByIdObs: Observable<Account> = this.masterDataManager.getAccountById(accountId);
    let accountGenres: SelectionItem[] = [];

    this.subscriptions.add(
      getAccountByIdObs.subscribe(
        (accountItem: Account) => {
          selectedAccount = accountItem;

          accountGenres = this.getFilteredGenresByAccount(selectedAccount.id).map(
            (genre: SelectionItem) => {
              return new SelectionItem(
                genre.label,
                genre.value,
                genre.source,
                selectedAccount.name
              );
            }
          );

          if (accountGenres.length !== 0) {
            this.filteredGenres = accountGenres;
          } else {
            this.getFilteredGenresByType();
          }

          this.loadingGenres = false;
        },
        (error: HttpError) => {
          this.getFilteredGenresByType();
          this.loadingGenres = false;
          this.notificationService.handleError('Failed trying to fetch the Account', error);
        }
      )
    );
  }

  /**
   * Filter official ratings with the given territory id.
   *
   * @param territoryId number[]
   * @returns void
   */
  private filterOfficialRatings(territoryId: number[]): void {
    this.filteredRatings = this.ratings.filter(
      (rating: SelectionItem) => {
        const isOfficial: boolean = rating.source.ratingSystem.type === RatingSystemType.OFFICIAL;
        let matched: boolean = false;
        const territories: Country[] = rating.source.ratingSystem.territories;

        if (_isArray(territories)) {
          matched = territories.some((territory: Country) => territoryId.includes(territory.id));
        }

        return isOfficial && matched;
      }
    ).map(
      (rating: SelectionItem) => {
        return new SelectionItem(
          rating.label,
          rating.value,
          rating.source,
          rating.source.ratingSystem.name
        );
      }
    );
  }

  /**
   * Loads the genre list.
   *
   * @returns void
   */
  private loadGenres(): void {
    this.loadingGenres = true;

    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(TypeEnum.genre).subscribe(
        (list: List) => {
          this.genres = list.items;
          this.filteredGenres = this.genres;
          this.loadingGenres = false;
          this.filterGenres(false);
        },
        (error: HttpError) => {
          this.notificationService.handleError(
            'Failed trying to fetch the genre list',
            error
          );

          this.loadingGenres = false;
        }
      )
    );
  }

  /**
   * Loads the rating list.
   *
   * @returns void
   */
  private loadRatings(): void {
    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(TypeEnum.rating).subscribe(
        (list: List) => {
          this.ratings = list.items;
          this.filterRatings(false);
        },
        (error: HttpError) => {
          this.notificationService.handleError(
            'Failed trying to fetch the rating list',
            error
          );
        }
      )
    );
  }

  /**
   * Loads the rating system reason list.
   *
   * @returns void
   */
  private loadRatingsSystemReason(): void {
    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(TypeEnum.ratingSystemReason).subscribe(
        (list: List) => {
          this.ratingsSystemReason = list.items;
          this.filterRatingsSystemReason(false);
          this.filterHomeEntertainmentRatingsSystemReason(false);
        },
        (error: HttpError) => {
          this.notificationService.handleError(
            'Failed trying to fetch the rating system reason list',
            error
          );
        }
      )
    );
  }

}
