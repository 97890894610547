import { Component, OnInit } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { EnvironmentService } from '@bolt/ui-shared/common';
import { AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { AuthHelper } from 'app/modules/auth/helpers/auth/auth.helper';
import { LogoOptionEnum } from './logo-option.enum';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { User } from 'app/modules/user/models/user.model';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';


@Component({
  selector: 'bolt-header',
  template: require('./bolt-header.html'),
  styles: [require('./bolt-header.scss')]
})
export class BoltHeaderComponent implements OnInit {
  protected logo: LogoOptionEnum;
  protected modulesPath: typeof modulesPath = modulesPath;
  protected user: User;

  constructor(
    protected appConfig: AppConfigProvider,
    protected authHelper: AuthHelper,
    protected environment: EnvironmentService,
    protected router: Router,
    protected userManager: UserManager,
    protected appConfigurationManager: AppConfigurationManager
  ) {
    this.initialize();
  }

  ngOnInit() {
    this.discoverLogo();
    this.discoverUser();
  }

  /**
   * Discovers the logo to display.
   *
   * @returns void
   */
  protected discoverLogo(): void {
    this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          if (this.hasDisplayMittensLogo(event.url)) {
            this.logo = LogoOptionEnum.mittens;
          } else if (this.hasDisplayRhinoLogo(event.url)) {
            this.logo = LogoOptionEnum.rhino;
          } else {
            this.logo = LogoOptionEnum.bolt;
          }
        }
      }
    );
  }

  /**
   * Discovers the logged in user.
   *
   * @returns void
   */
  protected discoverUser(): void {
    this.authHelper.isAuthenticatedStream.subscribe(
      (authenticated: boolean) => {
        if (authenticated && this.authHelper.authEnabled) {
          this.userManager.fetchAuthenticatedUser().subscribe(
            (response: any) => {
              this.user = response.item;
            }
          );
        } else {
          this.user = undefined;
        }
      }
    );
  }

  /**
   * Indicates if it has to display Mittens logo using the given URL.
   *
   * @param url string
   * @returns boolean
   */
  protected hasDisplayMittensLogo(url: string): boolean {
    let hasIt: boolean = (
      url.startsWith(`/${this.appConfig.get('ux.page.cat.subproductDetailsPageKey')}/`) ||
      url.startsWith(`/${this.appConfig.get('ux.page.cat.termDetailsPageKey')}/`)
    );

    if (!hasIt) {
      const showMark: string = 'show=';

      if (url.includes(showMark)) {
        const parts: string[] = url.split(showMark);

        if (parts.length > 1) {
          hasIt = (parts[1].split(';')[0] === this.appConfig.get('ux.page.cat.tabsName.itemsList'));
        } else {
          hasIt = false;
        }
      }
    }

    return hasIt;
  }

  /**
   * Indicates if it has to display Rhino logo using the given URL.
   *
   * @param url string
   * @returns boolean
   */
  protected hasDisplayRhinoLogo(url: string): boolean {
    const hasIt: boolean = url.startsWith(`/${this.appConfig.get('ux.page.projectDashboard.detailsPageKey')}`);
    return hasIt;
  }

  /**
   * Indicates if it has a user.
   *
   * @returns boolean
   */
  protected hasUser(): boolean {
    return _isObject(this.user);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.logo = LogoOptionEnum.bolt;
  }
}
