import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { Credit } from 'app/modules/credits/models/credit/credit.model';
import { CreditLockingStatusInterface } from 'app/modules/credits/models/credit/credit-locking-status.interface';
import { TitleType, TitleInterface } from 'app/modules/title/models/title.model';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { CreditFactory } from '../helpers/credit-factory/credit-factory.helper';
import { Talent } from 'app/modules/talent/models/talent.model';
import { Character } from 'app/modules/character/models/character.model';
import { Role } from 'app/modules/role/models/role.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { CreditLocale } from '../models/credit/credit-locale.model';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';


@Injectable({
  providedIn: 'root'
})
export class CreditsService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetching credits using the given title parameters.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param creditType CreditType
   * @param creditPositionType CreditPositionType
   * @param locale string
   * @returns Observable<Credit[]>
   */
  fetch(
    titleType: TitleType,
    titleId: number,
    creditType: CreditType,
    creditPositionType: CreditPositionType,
    locale: string
  ): Observable<Credit[]> {
    const apiEndpoint: string =
      'creditsService.fetch' +
      (creditType === CreditType.ORIGINAL ? 'Original' : '') +
      '.endpoint';

    const url: string = this.generateUrl(
      apiEndpoint,
      {
        '{titleType}': titleType.toString().toLowerCase(),
        '{titleId}': titleId,
        '{creditType}': creditType.toString().toLowerCase(),
        '{creditPositionType}': creditPositionType.toString().toLowerCase(),
        '{locale}': locale
      }
    );

    const obs: Observable<Credit[]> = this.doGetRequestAsObservable({ url: url, checkType: CheckTypeEnum.array }).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const credits: Credit[] = response.item.map(
            (item: any) => {
              Object.assign(item, item.member);
              delete item.member;

              item.talent = new Talent(item.talent);

              item.position = item.character
                ? new Character(item.character)
                : new Role(item.role);

              return CreditFactory(
                item.character ? CreditPositionType.CAST : CreditPositionType.CREW,
                item
              );
            }
          );

          return _.sortBy(credits, ['order']);
        }
      )
    );


    return obs;
  }

  /**
   * Fetching the credit locales for the given data.
   *
   * @param productType TitleType
   * @param productId number
   * @param creditType CreditType
   * @param creditPositionType CreditPositionType
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Observable<CreditLocale[]>
   */
  fetchLocales(
    productType: TitleType,
    productId: number,
    creditType: CreditType,
    creditPositionType: CreditPositionType,
  ): Observable<CreditLocale[]> {
    const url: string = this.generateUrl(
      'creditsService.fetchLocales.endpoint',
      {
        '{titleType}': productType,
        '{titleId}': productId,
        '{creditType}': creditType.toString().toLowerCase(),
        '{creditPositionType}': creditPositionType.toString().toLowerCase()
      }
    );

    const obs: Observable<CreditLocale[]> = this.doGetRequestAsObservable({ url: url, checkType: CheckTypeEnum.array }).pipe(
      map(
        (successResponse: StormServiceResponseSingle) => {
          const elements: CreditLocale[] = new Array();

          successResponse.item.forEach(
            (rawData: any) => {
              this.mapForCreditLocale(rawData);

              const element: CreditLocale = new CreditLocale(rawData);
              elements.push(element);
            }
          );

          return elements;
        }
      )
    );

    return obs;
  }

  /**
   * Fetching the Locking Status of a Credit.
   * Maps 404 error in a default Locking Status.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param creditType CreditType
   * @param creditPositionType CreditPositionType
   * @param locale string
   * @returns Observable<CreditLockingStatusInterface>
   */
  fetchLockingStatus(
    titleType: TitleType,
    titleId: number,
    creditType: CreditType,
    creditPositionType: CreditPositionType,
    locale: string
  ): Observable<CreditLockingStatusInterface> {
    let url: string;

    if (creditType === CreditType.ORIGINAL) {
      url = this.generateUrl(
        'creditsService.fetchOriginalLockingStatus.endpoint',
        {
          '{titleType}': titleType,
          '{titleId}': titleId,
          '{creditPositionType}': creditPositionType.toString().toLowerCase()
        }
      );
    } else {
      url = this.generateUrl(
        'creditsService.fetchLocalizedLockingStatus.endpoint',
        {
          '{titleType}': titleType,
          '{titleId}': titleId,
          '{locale}': locale,
          '{creditType}': creditType.toString().toLowerCase(),
          '{creditPositionType}': creditPositionType.toString().toLowerCase()
        }
      );
    }

    const obs: Observable<CreditLockingStatusInterface> = Observable.create(
      (observer: Observer<CreditLockingStatusInterface>) => {
        this.doGetRequestAsObservable({ url: url }).pipe(
          map(
            (response: StormServiceResponseSingle) => {
              const mappedResponse: any = {
                titleType: titleType,
                titleId: titleId,
                locale: locale,
                creditType: creditType,
                creditPositionType: creditPositionType,
                locked: response.item.locked,
                lockedBy: response.item.lockedBy,
                lockedDate: response.item.lockedDate,
                lockReason: response.item.lockReason
              };

              return mappedResponse;
            }
          )
        ).subscribe(
          (response: CreditLockingStatusInterface) => {
            observer.next(response);
            observer.complete();
          },
          (error: ErrorHelper) => {
            if (error.is404()) {
              const response: any = {
                titleType: titleType,
                titleId: titleId,
                locale: locale,
                creditType: creditType,
                creditPositionType: creditPositionType,
                locked: false,
                lockedBy: null,
                lockedDate: null
              };

              observer.next(response);
            } else {
              observer.error(error);
            }

            observer.complete();
          }
        );
      }
    );

    return obs;
  }

  /**
   * Toggling the credit lock status
   *
   * @param creditLockingStatus CreditLockingStatusInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  toggleLockingStatus(creditLockingStatus: CreditLockingStatusInterface): Observable<StormServiceResponseSingle> {
    let url: string;
    const params = creditLockingStatus.lockReason ? JSON.stringify({ reason: creditLockingStatus.lockReason }) : '';

    if (creditLockingStatus.creditType === CreditType.ORIGINAL) {
      url = this.generateUrl(
        'creditsService.updateOriginalLockingStatus.endpoint',
        {
          '{titleType}': creditLockingStatus.titleType,
          '{titleId}': creditLockingStatus.titleId,
          '{creditPositionType}': creditLockingStatus.creditPositionType.toString().toLowerCase(),
          '{operation}': creditLockingStatus.locked ? 'lock' : 'unlock',
        }
      );
    } else {
      url = this.generateUrl(
        'creditsService.updateLocalizedLockingStatus.endpoint',
        {
          '{titleType}': creditLockingStatus.titleType,
          '{titleId}': creditLockingStatus.titleId,
          '{locale}': creditLockingStatus.locale,
          '{creditType}': creditLockingStatus.creditType.toString().toLowerCase(),
          '{creditPositionType}': creditLockingStatus.creditPositionType.toString().toLowerCase(),
          '{operation}': creditLockingStatus.locked ? 'lock' : 'unlock',
        }
      );
    }

    return this.doPostRequestAsObservable({ url: url, body: params });
  }

  /**
   * Creating a credit with the given params.
   *
   * @param title TitleInterface
   * @param positionType CreditPositionType
   * @param credit Credit
   * @returns Observable<Credit>
   */
  create(title: TitleInterface, positionType: CreditPositionType, credit: Credit): Observable<Credit> {
    const apiEndpoint: string = credit.getType() === CreditType.ORIGINAL
      ? 'creditsService.createOriginal.endpoint'
      : 'creditsService.create.endpoint';

    const url: string = this.generateUrl(
      apiEndpoint,
      {
        '{titleType}': title.type.toString(),
        '{titleId}': title.id.toString(),
        '{locale}': credit.locale,
        '{creditType}': credit.getType().toString().toLowerCase(),
        '{creditPositionType}': positionType.toString().toLowerCase(),
      }
    );

    const body: any = JSON.stringify(credit.getRawObject());

    const obs: Observable<Credit> = this.doPostRequestAsObservable({ url: url, body: body }).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          return CreditFactory(positionType, response);
        }
      )
    );

    return obs;
  }

  /**
   * Deleting a credit with the given params.
   *
   * @param title TitleInterface
   * @param positionType CreditPositionType
   * @param credit Credit
   * @returns Observable<StormServiceResponseSingle>
   */
  delete(title: TitleInterface, positionType: CreditPositionType, credit: Credit): Observable<StormServiceResponseSingle> {
    const url: string = this.generateUrl(
      'creditsService.delete.endpoint',
      {
        '{titleType}': title.type,
        '{creditPositionType}': positionType.toString().toLowerCase(),
        '{creditId}': credit.id,
      }
    );

    return this.doDeleteRequestAsObservable({ url: url, checkType: CheckTypeEnum.boolean });
  }

  /**
   * Updating a credit with the given params.
   *
   * @param title TitleInterface
   * @param positionType CreditPositionType
   * @param credit Credit
   * @returns Observable<Credit>
   */
  update(title: TitleInterface, positionType: CreditPositionType, credit: Credit): Observable<Credit> {
    const url: string = this.generateUrl(
      'creditsService.update.endpoint',
      {
        '{titleType}': title.type,
        '{creditPositionType}': positionType.toString().toLowerCase(),
        '{creditId}': credit.id,
      }
    );

    const body: any = JSON.stringify(
      _.pick(
        credit.getRawObject(),
        ['featureId', 'seasonId', 'talentId', 'characterId', 'roleId', 'order', 'type', 'isGuest']
      )
    );

    const obs: Observable<Credit> = this.doPutRequestAsObservable({ url: url, body: body }).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          return CreditFactory(positionType, response.item);
        }
      )
    );

    return obs;
  }

  /**
   * Maps the given data in order to create a new credit locale.
   *
   * @param data any
   * @returns void
   */
  protected mapForCreditLocale(data: any): void {
    data.language = this.listLayoutProvider.getLanguageByLocale(data.locale);
    data.territory = [this.listLayoutProvider.getTerritoryByLocale(data.locale)];
  }
}
