import {
  AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChild, ViewChildren, Input, OnChanges, SimpleChanges
} from '@angular/core';

import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { CustomErrorTypeEnum } from '@bolt/ui-shared/form';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { clone as _clone, isNull as _isNull, isObject as _isObject, isUndefined as _isUndefined } from 'lodash';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { Decision } from '../../models/subtitle/details/decision/decision.model';
import { DecisionEnum } from '../../models/subtitle/details/decision/decision.enum';
import { HeightSynchronizerService } from '../../services/height-synchronizer/height-synchronizer.service';
import { ManagerService } from '../../services/manager/manager.service';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { SubtitleDetail } from '../../models/subtitle-detail.model';
import { SubtitleType } from '../../models/subtitle/details/subtitle-type/subtitle-type.model';
import { SubtitleTypeEnum } from '../../models/subtitle/details/subtitle-type/subtitle-type.enum';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data-form.model';


@Component({
  selector: 'bolt-subtitle-localized-panel',
  template: require('./bolt-subtitle-localized-panel.html'),
  styles: [require('./bolt-subtitle-localized-panel.scss')]
})
export class BoltSubtitleLocalizedPanelComponent extends StormComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() subtitleType: SubtitleTypeEnum;

  @ViewChild('confirmLockingModal')
  protected confirmLockingModal: any;

  @ViewChildren('heightSynchronizingItem')
  protected heightSynchronizingItemList: QueryList<ElementRef>;

  protected boltStore: any;
  protected decisionList: Decision[];
  protected subtitleForm: FormGroup;
  protected subtitleFormCustomErrors: Map<CustomErrorTypeEnum, string>;
  protected attributes: any;
  protected subtitleTypeWrapper: SubtitleType;

  constructor(
    protected capabilitiesManager: CapabilitiesManager,
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected managerService: ManagerService,
    protected heightSynchronizer: HeightSynchronizerService,
    protected formConfig: FormConfigService
  ) {
    super();
    this.attributes = this.formConfig.get('subtitles.fields');
    this.subtitleTypeWrapper = new SubtitleType(this.subtitleType);

    this.setupBoltStore();
    this.setupDecisionList();
    this.changeActionToShowing();
    this.changeStatusToIdle();
    this.observeOriginalVersionStatusChange();
    this.observeSelectedLocalizationChange();
    this.subtitleFormCustomErrors = new Map();
    this.subtitleFormCustomErrors.set(CustomErrorTypeEnum.maxLengthLine, 'One line exceed the max length limit.');
  }

  ngAfterViewInit() {
    this.heightSynchronizingItemList.changes.subscribe(
      () => {
        this.heightSynchronizer.setLocalizedItems(this.heightSynchronizingItemList.toArray());
      }
    );
  }

  ngOnDestroy() {
    this.heightSynchronizer.resetLocalizedItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.subtitleTypeWrapper = new SubtitleType(this.subtitleType);
    this.setupSubtitleForm();
  }

  /**
   * Cancels the edition.
   *
   * @returns void
   */
  onCancel(): void {
    this.changeActionToShowing();
    this.fetch();
    this.managerService.turnOffAnyVersionOnEdition();
  }

  /**
   * Allows the edition.
   *
   * @returns void
   */
  onEdit(): void {
    this.managerService.turnOnAnyVersionOnEdition();
    this.changeActionToUpdating();
  }

  /**
   * Toggles the locking status.
   *
   * @returns void
   */
  onToggleLockingStatus(): void {
    this.confirmLockingModal.lock = this.managerService.localizedVersion.lockedStatus.locked;

    this.modalService.open(this.confirmLockingModal).result
      .then(
        (confirmToggle: boolean) => {
          if (confirmToggle) {
            this.changeStatusToFetchingData();

            this.managerService.toggleLockingStatusForLocalizedVersion(
              () => {
                this.fetch();
              },
              () => {
                this.changeStatusToDataFound();
              }
            );
          }
        }
      )
      .catch(
        (reason: any) => { }
      );
  }

  /**
   * Saves the data.
   *
   * @returns void
   */
  onSave(): void {
    this.changeStatusToFetchingData();

    this.managerService.updateSubtitleDetailsInLocalizedVersion(
      this.retrieveFormData(),
      this.retrieveUntouchedData()
    );

    this.managerService.saveLocalizedVersion(
      () => {
        this.fetch();
        this.changeActionToShowing();
        this.managerService.turnOffAnyVersionOnEdition();
      },
      () => {
        this.changeStatusToDataFound();
      }
    );
  }

  /**
   * Obtains the data that has not been modified
   *
   * returns Array<SubtitleDetail>
   */
  protected retrieveUntouchedData(): SubtitleDetail[] {
    let untouchedLocalizations: SubtitleDetail[] = null;

    if (this.subtitleTypeWrapper.isAll()) {
      return untouchedLocalizations;
    }

    if (this.managerService && this.managerService.localizedVersion) {
      untouchedLocalizations = this.managerService.localizedVersion.subtitleDetails.filter(
        (value: SubtitleDetail) => value.subtitleType.type !== this.subtitleType
      );
    }

    return untouchedLocalizations;
  }

  /**
   * Fetches the data.
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();
    this.managerService.resetLocalizedVersion();

    this.managerService.fetchLocalizedVersion(
      () => {
        this.setupSubtitleForm();
        this.changeStatusToDataFound();
      },
      () => {
        this.changeStatusToError();
      }
    );
  }

  /**
   * Indicates if it has all needed data.
   *
   * @returns boolean
   */
  protected hasAllNeededData(): boolean {
    const hasIt: boolean = (
      this.managerService.hasLanguages() &&
      this.managerService.hasTerritories() &&
      this.isDataFound()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the lock button.
   *
   * @returns boolean
   */
  protected hasBlockLock(): boolean {
    const hasIt: boolean = (
      this.managerService.isAnyVersionInEdition() ||
      !this.managerService.localizedVersion.isPersisted() ||
      !this.canToggleLockingStatus()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the edit button.
   *
   * @returns boolean
   */
  protected hasBlockEdit(): boolean {
    const hasIt: boolean = (
      this.managerService.isAnyVersionInEdition() ||
      this.managerService.localizedVersion.lockedStatus.locked
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const canIt: boolean = (
      this.subtitleForm.invalid &&
      (<FormArray>this.subtitleForm.get('localizedForms')).length > 0
    );

    return canIt;
  }

  /**
   * Determines if edit button should be shown
   *
   * @returns boolean
   */
  protected hasDisplayEditButton(): boolean {
    const itShows: boolean = this.capabilitiesManager.hasUserPrivilegeOn(
      modulesPath.titles.subtitles.localized.path, [ActionTypeEnum.write]
    );

    return itShows;
  }

  /**
   * Indicates if it can toggle the locking status.
   *
   * @returns boolean
   */
  protected canToggleLockingStatus(): boolean {
    let itCan: boolean;

    if (this.managerService.localizedVersion.lockedStatus.locked) {
      itCan = this.capabilitiesManager.hasUserPrivilegeOn(
        modulesPath.titles.subtitles.localized.path, [ActionTypeEnum.unlock]
      );
    } else {
      itCan = this.capabilitiesManager.hasUserPrivilegeOn(
        modulesPath.titles.subtitles.localized.path, [ActionTypeEnum.lock]
      );
    }

    return itCan;
  }

  /**
   * Indicates if the original version is in edition.
   *
   * @returns boolean
   */
  protected isOriginalVersionInEdition(): boolean {
    const isIt: boolean = (
      this.isShowingAction() &&
      this.managerService.isAnyVersionInEdition()
    );

    return isIt;
  }

  /**
   * Returns a new form for an entry.
   *
   * @param subtitleDetails SubtitleDetail
   * @returns FormGroup
   */
  protected newEntryForm(subtitleDetails?: SubtitleDetail): FormGroup {
    const fieldSpecs: any = this.formConfig.get('subtitles.fields');

    const decisionValue: string = (
      subtitleDetails.decision ? subtitleDetails.decision : this.getDefaultDecision(subtitleDetails)
    ).toString();

    const defaultValues: any = {
      rootId: subtitleDetails ? subtitleDetails.rootId : undefined,
      id: subtitleDetails ? subtitleDetails.id : undefined,
      narrativeTitle: subtitleDetails ? subtitleDetails.narrativeTitle : undefined,
      subtitleType: subtitleDetails ? subtitleDetails.subtitleType.toString() : '',
      decision: decisionValue
    };

    const entryForm: LocalizedDataForm = new LocalizedDataForm(fieldSpecs, defaultValues);

    entryForm.get('decision').valueChanges.subscribe(
      () => {
        this.updateTranslationEnabling(entryForm);
      }
    );

    return entryForm;
  }

  /**
   * Gets the default decision for the entry depending on the subtitle type
   *
   * @param subtitleDetails SubtitleDetail
   * @returns DecisionEnum
   */
  protected getDefaultDecision(subtitleDetails?: SubtitleDetail): DecisionEnum {
    let decision: DecisionEnum;

    if (subtitleDetails.subtitleType.isLyrics()) {
      decision = DecisionEnum.USE_TRANSLATED_TEXT;
    } else {
      decision = DecisionEnum.NO_TRANSLATION_NEEDED;
    }

    return decision;
  }

  /**
   * Observes the change of the selected localization.
   *
   * @returns void
   */
  protected observeSelectedLocalizationChange(): void {
    this.managerService.selectedLocalizationChangeTriggered.subscribe(
      (localization) => {
        this.changeActionToShowing();
        this.managerService.turnOffAnyVersionOnEdition();

        if (!this.isFetchingData()) {
          this.fetch();
        }
      }
    );
  }

  /**
   * Observes the status change of the original version.
   *
   * @returns void
   */
  protected observeOriginalVersionStatusChange(): void {
    this.managerService.originalVersionStatusTriggered.subscribe(
      (status: DataStatusEnum) => {
        switch (status) {
          case DataStatusEnum.error:
            this.managerService.resetLocalizedVersion();
            this.changeStatusToError();
          break;
          case DataStatusEnum.dataFound:
            this.fetch();
          break;
          case DataStatusEnum.fetchingData:
            this.managerService.resetLocalizedVersion();
            this.changeStatusToFetchingData();
          break;
          default:
          break;
        }
      }
    );
  }

  /**
   * Retrieves the data in the form.
   *
   * @returns Array<any>
   */
  protected retrieveFormData(): any[] {
    const formData: any[] = new Array();

    (<FormArray>this.subtitleForm.get('localizedForms')).controls.forEach(
      (entry: LocalizedDataForm) => {
        const data: any = entry.toJson();

        formData.push(data);
      }
    );

    return formData;
  }

  /**
   * Set up the BoltStore for containing UI basic data like translations.
   *
   * @returns void
   */
  protected setupBoltStore(): void {
    this.boltStore = {
      translations: {
        commands: {
          cancel: 'Cancel',
          edit: 'Edit',
          lock: 'Lock',
          save: 'Save',
          unlock: 'Unlock'
        },
        modal: {
          message: 'Are you sure you want to'
        },
        table: {
          header: {
            narrativeTitle: 'Translation',
            decision: 'Decision'
          },
          body: {
            noSubtitlesFound: 'No subtitles found.'
          }
        }
      }
    };
  }

  /**
   * Set up the decision list.
   *
   * @returns void
   */
  protected setupDecisionList(): void {
    this.decisionList = new Array();

    this.decisionList.push(new Decision(DecisionEnum.COVERED_IN_DUB));
    this.decisionList.push(new Decision(DecisionEnum.NO_TRANSLATION_NEEDED));
    this.decisionList.push(new Decision(DecisionEnum.USE_TRANSLATED_TEXT));
    this.decisionList.push(new Decision(DecisionEnum.VOICE_OVER));
  }

  /**
   * Sets up the entries form.
   *
   * @returns void
   */
  protected setupSubtitleForm(): void {
    const entries: FormGroup[] = new Array();

    this.getFilteredSubtitles().forEach(
      (subtitleDetails: SubtitleDetail) => {
        const entryForm: FormGroup = this.newEntryForm(subtitleDetails);

        this.updateTranslationEnabling(entryForm);

        entries.push(entryForm);
      }
    );

    this.subtitleForm = this.formBuilder.group({
      localizedForms: this.formBuilder.array(entries)
    });
  }

  /**
   * Updates the narrativeTitle enabling in the given form group.
   *
   * @param entryForm FormGroup
   * @returns void
   */
  protected updateTranslationEnabling(entryForm: FormGroup): void {
    if (entryForm.get('decision').value === DecisionEnum.USE_TRANSLATED_TEXT) {
      entryForm.get('narrativeTitle').enable();
    } else {
      entryForm.get('narrativeTitle').disable();
      entryForm.get('narrativeTitle').setValue(undefined);
    }
  }

  /**
   * Determines if the line of empty subtitles should be shown
   *
   * @returns boolean
   */
  protected showEmptySubtitles(): boolean {
    const hasIt = this.getFilteredSubtitles().length === 0;
    return hasIt;
  }

  /**
   * Gets the list of the subtitles depending on the current subtitle type
   *
   * @returns Array<SubtitleDetail>
   */
  protected getFilteredSubtitles(): SubtitleDetail[] {
    let entries: SubtitleDetail[] = new Array();

    if (this.managerService && this.managerService.localizedVersion) {

      if (this.subtitleTypeWrapper.isAll()) {
        entries = _clone(this.managerService.localizedVersion.subtitleDetails);
      } else {
        entries = this.managerService.localizedVersion.subtitleDetails.filter(
          (value: SubtitleDetail) =>  value.subtitleType.type === this.subtitleType
        );
      }
    }

    return entries;
  }
}
