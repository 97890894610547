import {
  AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, Input, SimpleChanges, OnChanges, ViewChild, ViewChildren
} from '@angular/core';

import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { clone as _clone } from 'lodash';
import { CustomErrorTypeEnum } from '@bolt/ui-shared/form';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { HeightSynchronizerService } from '../../services/height-synchronizer/height-synchronizer.service';
import { ManagerService } from '../../services/manager/manager.service';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { SubtitleDetail } from '../../models/subtitle-detail.model';
import { SubtitleType } from '../../models/subtitle/details/subtitle-type/subtitle-type.model';
import { SubtitleTypeEnum } from '../../models/subtitle/details/subtitle-type/subtitle-type.enum';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';


@Component({
  selector: 'bolt-subtitle-original-panel',
  template: require('./bolt-subtitle-original-panel.html'),
  styles: [require('./bolt-subtitle-original-panel.scss')]
})
export class BoltSubtitleOriginalPanelComponent extends StormComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input() subtitleType: SubtitleTypeEnum;

  @ViewChild('confirmLockingModal')
  protected confirmLockingModal: any;

  @ViewChildren('heightSynchronizingItem')
  protected heightSynchronizingItemList: QueryList<ElementRef>;

  protected boltStore: any;
  protected subtitleForm: FormGroup;
  protected subtitleFormCustomErrors: Map<CustomErrorTypeEnum, string>;
  protected subtitleTypes: SelectionItem[];
  protected subtitleTypeWrapper: SubtitleType;
  constructor(
    protected capabilitiesManager: CapabilitiesManager,
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected managerService: ManagerService,
    protected heightSynchronizer: HeightSynchronizerService,
    protected formConfig: FormConfigService
  ) {
    super();
    this.setupBoltStore();
    this.setupSubtitleTypeList();
    this.changeActionToShowing();
    this.changeStatusToIdle();

    this.subtitleFormCustomErrors = new Map();
    this.subtitleFormCustomErrors.set(CustomErrorTypeEnum.maxLengthLine, 'One line exceed the max length limit.');
  }

  ngAfterViewInit() {
    this.heightSynchronizingItemList.changes.subscribe(
      () => {
        this.heightSynchronizer.setOriginalItems(this.heightSynchronizingItemList.toArray());
      }
    );
  }

  ngOnDestroy() {
    this.heightSynchronizer.resetOriginalItems();
  }

  ngOnInit() {
    this.fetch();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.subtitleTypeWrapper = new SubtitleType(this.subtitleType);
    this.setupSubtitleForm();
  }

  /**
   * Adds the first subtitle detail.
   *
   * @returns void
   */
  onAddFirstSubtitleDetail(): void {
    this.onAddNewSubtitleDetail();
    this.onEdit();
  }

  /**
   * Adds a new subtitle detail.
   *
   * @returns void
   */
  onAddNewSubtitleDetail(): void {
    (<FormArray>this.subtitleForm.get('originalForms')).push(this.newEntryForm());
  }

  /**
   * Cancels the edition.
   *
   * @returns void
   */
  onCancel(): void {
    this.changeActionToShowing();
    this.fetch();
    this.managerService.turnOffAnyVersionOnEdition();
  }

  /**
   * Allows the edition.
   *
   * @returns void
   */
  onEdit(): void {
    this.managerService.turnOnAnyVersionOnEdition();
    this.changeActionToUpdating();
  }

  /**
   * Removes the subtitle in the given position.
   *
   * @param position number
   * @returns void
   */
  onRemoveSubtitleDetail(position: number): void {
    (<FormArray>this.subtitleForm.get('originalForms')).removeAt(position);
  }

  /**
   * Toggles the locking status.
   *
   * @returns void
   */
  onToggleLockingStatus(): void {
    this.confirmLockingModal.lock = this.managerService.originalVersion.lockedStatus.locked;

    this.modalService.open(this.confirmLockingModal).result
      .then(
        (confirmToggle: boolean) => {
          if (confirmToggle) {
            this.changeStatusToFetchingData();

            this.managerService.toggleLockingStatusForOriginalVersion(
              () => {
                this.fetch();
              },
              () => {
                this.changeStatusToDataFound();
              }
            );
          }
        }
      )
      .catch(
        (reason: any) => { }
      );
  }

  /**
   * Saves the data.
   *
   * @returns void
   */
  onSave(): void {
    this.changeStatusToFetchingData();

    this.managerService.updateSubtitleDetailsInOriginalVersion(
      this.retrieveFormData(),
      this.retrieveUntouchedData()
    );

    this.managerService.saveOriginalVersion(
      () => {
        this.fetch();
        this.changeActionToShowing();
        this.managerService.turnOffAnyVersionOnEdition();
      },
      () => {
        this.changeStatusToDataFound();
      }
    );
  }

  /**
   * Obtains the data that has not been modified
   *
   * returns Array<SubtitleDetail>
   */
  protected retrieveUntouchedData(): SubtitleDetail[] {
    let untouchedSubtitles: SubtitleDetail[] = null;

    if (this.subtitleTypeWrapper.isAll()) {
      return untouchedSubtitles;
    }

    if (this.managerService && this.managerService.originalVersion) {
      untouchedSubtitles = this.managerService.originalVersion.subtitleDetails.filter(
        (value: SubtitleDetail) => value.subtitleType.type !== this.subtitleType
      );
    }

    return untouchedSubtitles;
  }

  /**
   * Fetches the data.
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();
    this.managerService.resetOriginalVersion();

    this.managerService.fetchOriginalVersion(
      () => {
        this.setupSubtitleForm();
        this.changeStatusToDataFound();
      },
      () => {
        this.changeStatusToError();
      }
    );
  }

  /**
   * Indicates if it has all needed data.
   *
   * @returns boolean
   */
  protected hasAllNeededData(): boolean {
    const hasIt: boolean = (
      this.managerService.hasLanguages() &&
      this.managerService.hasTerritories() &&
      this.isDataFound()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the lock button.
   *
   * @returns boolean
   */
  protected hasBlockLock(): boolean {
    const itHas: boolean = this.managerService.isAnyVersionInEdition() || !this.canToggleLockingStatus();
    return itHas;
  }

  /**
   * Indicates if it has to block the edit button.
   *
   * @returns boolean
   */
  protected hasBlockEdit(): boolean {
    const hasIt: boolean = (
      this.managerService.isAnyVersionInEdition() ||
      this.managerService.originalVersion.lockedStatus.locked
    );

    return hasIt;
  }

  /**
   * Indicates if it can display the container.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const canIt: boolean = (
      this.subtitleForm.invalid &&
      (<FormArray>this.subtitleForm.get('originalForms')).length > 0
    );

    return canIt;
  }

  /**
   * Determines if edit button should be shown
   *
   * @returns boolean
   */
  protected hasDisplayEditButton(): boolean {
    const itShows: boolean = this.capabilitiesManager.hasUserPrivilegeOn(
      modulesPath.titles.subtitles.original.path, [ActionTypeEnum.write]
    );

    return itShows;
  }

  /**
   * Indicates if it can toggle the locking status.
   *
   * @returns boolean
   */
  protected canToggleLockingStatus(): boolean {
    let itCan: boolean;

    if (this.managerService.originalVersion.lockedStatus.locked) {
      itCan = this.capabilitiesManager.hasUserPrivilegeOn(
        modulesPath.titles.subtitles.original.path, [ActionTypeEnum.unlock]
      );
    } else {
      itCan = this.capabilitiesManager.hasUserPrivilegeOn(
        modulesPath.titles.subtitles.original.path, [ActionTypeEnum.lock]
      );
    }

    return itCan;
  }

  /**
   * Returns a new form for an entry.
   *
   * @param subtitleDetails SubtitleDetail
   * @returns OriginalDataForm
   */
  protected newEntryForm(subtitleDetails?: SubtitleDetail): OriginalDataForm {
    let subtitleTypeValue: string;
    const fieldSpecs: any = this.formConfig.get('subtitles.fields');

    fieldSpecs.subtitleType = { required: true };

    if (this.subtitleTypeWrapper.isAll()) {
      subtitleTypeValue = subtitleDetails ? subtitleDetails.subtitleType.toString() : '';
    } else {
      subtitleTypeValue = subtitleDetails ? subtitleDetails.subtitleType.toString() : this.subtitleType.toString();
    }

    const defaultValues: any = {
      id: subtitleDetails ? subtitleDetails.id : undefined,
      timeStart: subtitleDetails ? subtitleDetails.timeStart : undefined,
      timeEnd: subtitleDetails ? subtitleDetails.timeEnd : undefined,
      narrativeTitle: subtitleDetails ? subtitleDetails.narrativeTitle : undefined,
      subtitleType: subtitleDetails ? subtitleTypeValue : undefined
    };

    return new OriginalDataForm(fieldSpecs, defaultValues);
  }

  /**
   * Retrieves the data in the form.
   *
   * @returns Array<any>
   */
  protected retrieveFormData(): any[] {
    const formData: any[] = new Array();

    (<FormArray>this.subtitleForm.get('originalForms')).controls.forEach(
      (entry: OriginalDataForm) => {
        const data: any = entry.toJson();

        formData.push(data);
      }
    );

    return formData;
  }

  /**
   * Set up the BoltStore for containing UI basic data like translations.
   *
   * @returns void
   */
  protected setupBoltStore(): void {
    this.boltStore = {
      translations: {
        commands: {
          addOne: 'Add one.',
          addSubtitle: 'Add Subtitle',
          cancel: 'Cancel',
          edit: 'Edit',
          lock: 'Lock',
          save: 'Save',
          unlock: 'Unlock'
        },
        mainTitle: 'Original Version',
        modal: {
          message: 'Are you sure you want to'
        },
        table: {
          header: {
            narrativeTitle: {
              description: 'On-screen text including Main & Episodic title where shown',
              title: 'Narrative Title / Subtitle'
            },
            timecodeEnd: 'Timecode End',
            timecodeStart: 'Timecode Start',
            type: 'Type'
          },
          body: {
            invalidTime: 'Expected HH:MM:SS:FF',
            noSubtitlesFound: 'No subtitles found.'
          }
        }
      }
    };
  }

  /**
   * Set up the subtitle type list.
   *
   * @returns void
   */
  protected setupSubtitleTypeList(): void {
    this.subtitleTypes = new Array();

    this.subtitleTypes.push(new SelectionItem(SubtitleTypeEnum.insert.toString(), new SubtitleType(SubtitleTypeEnum.insert)));
    this.subtitleTypes.push(new SelectionItem(SubtitleTypeEnum.lyrics.toString(), new SubtitleType(SubtitleTypeEnum.lyrics)));
  }

  /**
   * Sets up the entries form.
   *
   * @returns void
   */
  protected setupSubtitleForm(): void {
    const entries: FormGroup[] = new Array();

    this.getFilteredSubtitles().forEach(
      (subtitleDetails: SubtitleDetail) => {
        entries.push(this.newEntryForm(subtitleDetails));
      }
    );

    this.subtitleForm = this.formBuilder.group({
      originalForms: this.formBuilder.array(entries)
    });
  }

  /**
   * Determines if the line of empty subtitles should be shown
   *
   * @returns boolean
   */
  protected showEmptySubtitles(): boolean {
    const hasIt = this.getFilteredSubtitles().length === 0;
    return hasIt;
  }

  /**
   * Gets the list of the subtitles depending on the current subtitle type
   *
   * @returns Array<SubtitleDetail>
   */
  protected getFilteredSubtitles(): SubtitleDetail[] {
    let entries: SubtitleDetail[] = new Array();

    if (this.managerService && this.managerService.originalVersion) {
      if (this.subtitleTypeWrapper.isAll()) {
        entries = _clone(this.managerService.originalVersion.subtitleDetails);
      } else {
        entries = this.managerService.originalVersion.subtitleDetails.filter(
          (value: SubtitleDetail) =>  value.subtitleType.type === this.subtitleType
        );
      }
    }

    return entries;
  }
}
