import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { ActionTypeEnum, AppConfigProvider, ConfigurationTypeEnum } from '@bolt/ui-shared/configuration';
import { Country } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject } from 'lodash';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { CreditLocale } from '../../models/credit/credit-locale.model';
import { CollectionManagerCollectionInterface, CollectionManagerHelper } from 'app/modules/common/helpers/collection-manager.helper';
import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FeatureMetadata } from 'app/modules/title/models/feature-metadata.model';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { ProductLayoutHelper } from 'app/modules/title/helpers/product-layout/product-layout.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Title } from 'app/modules/title/models/title.model';
import { TitleService } from 'app/modules/title/services/title.service';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { CreditLockingStatusInterface } from 'app/modules/credits/models/credit/credit-locking-status.interface';
import { CreditsService } from '../../services/credits.service';


@Component({
  selector: 'bolt-credits-localization-list',
  template: require('./bolt-credits-localization-list.html'),
  styles: [require('./bolt-credits-localization-list.scss')]
})
export class BoltCreditsLocalizationListComponent extends StormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() protected title: Title;
  @Input() protected creditPositionType: CreditPositionType;
  @Input() protected creditType: CreditType;
  @Input() protected creditLocaleUpdated: CreditLocale;
  @Input() protected refresh: boolean = false;

  @Output('selected') protected selectEvent: EventEmitter<CreditLocale>;

  protected boltStore: any = { };
  protected collectionManagerSubscription: Subscription;
  protected creditsCollection: CollectionManagerCollectionInterface;
  protected creditLocaleForm: FormGroup;
  protected filterForm: FormGroup;
  protected localesLockingStatusSubscription: Subscription;
  protected localesSubscription: Subscription;
  protected selectedCredit: CreditLocale;
  protected translatedLockingStatus: Map<string, any>;

  constructor(
    protected appConfig: AppConfigProvider,
    protected capabilitiesManager: CapabilitiesManager,
    protected collectionManager: CollectionManagerHelper,
    protected formBuilder: FormBuilder,
    protected listProvider: ListLayoutProvider,
    protected notificationService: NotificationService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected titleService: TitleService,
    protected creditsService: CreditsService
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.collectionManagerSubscription.unsubscribe();
    this.cancelSubscriptions();
  }

  ngOnInit() {
    if (_isObject(this.title)) {
      this.fetchLocales();
    }

    this.setupBoltStore();
    this.setupFilter();
    this.setupForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    const refreshChanged: boolean =
      _isObject(changes.refresh) &&
      !changes.refresh.isFirstChange() &&
      changes.refresh.currentValue;

    const titleChanged: boolean =
      _isObject(changes.title) &&
      !changes.title.isFirstChange() &&
      changes.title.currentValue.id !== changes.title.previousValue.id;

    const creditTypeChanged: boolean =
      _isObject(changes.creditType) &&
      !changes.creditType.isFirstChange() &&
      changes.creditType.previousValue !== changes.creditType.currentValue;

    const creditPositionTypeChanged: boolean =
      _isObject(changes.creditPositionType) &&
      !changes.creditPositionType.isFirstChange() &&
      changes.creditPositionType.previousValue !== changes.creditPositionType.currentValue;

    if (titleChanged || creditTypeChanged || creditPositionTypeChanged) {
      this.cancelSubscriptions();
      this.fetchLocales();
    }

    if (refreshChanged) {
      this.refreshLockingStatus();
    }

    if (
      changes.creditLocaleUpdated &&
      _isObject(changes.creditLocaleUpdated.currentValue)
    ) {
      this.updateCreditLocale();
    }
  }

  /**
   * Adds the credit locale of the form in the currents credit locales.
   *
   * @returns void
   */
  protected addCreditLocale(): void {
    const languageId: number = this.creditLocaleForm.get('language').value;
    const territoryId: number = this.creditLocaleForm.get('territory').value;

    if (this.isAlreadyInCreditCollection(languageId, territoryId)) {
      this.notificationService.handleError('The locale is already in the list.');
    } else {
      const data: any = {
        language: this.listProvider.getLanguageById(languageId),
        territory: [this.listProvider.getTerritoryById(territoryId)],
      };

      const newCreditLocale: CreditLocale = new CreditLocale(data);

      this.collectionManager.addItemsToCollection('credits', [newCreditLocale]);
      this.creditLocaleForm.reset();
    }
  }

  /**
   * Cancel the current subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    this.localesSubscription.unsubscribe();
    this.localesLockingStatusSubscription.unsubscribe();
  }

  /**
   * Fetches the locales.
   *
   * @returns void
   */
  protected fetchLocales(): void {
    if (this.creditType === CreditType.TRANSLATED) {
      this.fetchTitleLocales();
    } else {
      this.fetchCreditLocales();
    }
  }

  /**
   * Fetches the credit locales for the current title.
   *
   * @returns void
   */
  protected fetchCreditLocales(): void {
    this.changeStatusToFetchingData();

    this.localesSubscription = this.creditsService.fetchLocales(
      this.title.type,
      this.title.id,
      this.creditType,
      this.creditPositionType
    ).subscribe(
      (response: CreditLocale[]) => {
        this.collectionManager.setCollectionItems('credits', response);
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to fetch the credit locales for the current title.', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Fetches the locking statuses of the given credits.
   *
   * @param credits CreditLocale[]
   * @returns void
   */
  protected fetchCreditLockingStatuses(credits: CreditLocale[]): void {
    this.translatedLockingStatus = new Map();
    const observables: Array<Observable<CreditLockingStatusInterface>> = new Array();

    credits.forEach(
      (credit: CreditLocale) => {
        const observable: Observable<CreditLockingStatusInterface> = this.creditsService.fetchLockingStatus(
          this.title.type,
          this.title.id,
          this.creditType,
          this.creditPositionType,
          credit.getLocale()
        );

        observable.subscribe(
          (lockingStatus: CreditLockingStatusInterface) => {
            credit.setLockingStatus(new LockingStatus(lockingStatus));
            this.translatedLockingStatus.set(credit.getLocale(), { status: DataStatusEnum.dataFound });
          },
          (error: ErrorHelper) => {
            this.translatedLockingStatus.set(
              credit.getLocale(),
              { status: DataStatusEnum.error, error: error.message }
            );
          }
        );

        observables.push(observable);
      }
    );

    this.localesLockingStatusSubscription = forkJoin(observables).subscribe();
  }

  /**
   * Fetches the title locales.
   *
   * @returns void
   */
  protected fetchTitleLocales(): void {
    this.changeStatusToFetchingData();

    this.localesSubscription = this.titleService.fetchProduct({
      productType: this.title.type,
      productId: this.title.id
    }).subscribe(
      serviceResponse => {
        const credits: CreditLocale[] = this.mapTitleLocales(
          (<FeatureMetadata[]>serviceResponse.item.localizations).filter(
            (localization: FeatureMetadata) => localization.localeObject.type.isLanguage()
          )
        );

        this.collectionManager.setCollectionItems('credits', credits);
        this.changeStatusToDataFound();
        this.localesLockingStatusSubscription.unsubscribe();
        this.fetchCreditLockingStatuses(credits);
      }
    );
  }

  /**
   * Get the languages options.
   *
   * @returns SelectionItem[]
   */
  protected getLanguageOptions(): SelectionItem[] {
    return this.listProvider.getLanguages(true);
  }

  /**
   * Get the territories options.
   *
   * @returns SelectionItem[]
   */
  protected getTerritoryOptions(): SelectionItem[] {
    return this.listProvider.getTerritories();
  }

  /**
   * Indicates if it has to block the add localization button.
   *
   * @returns boolean
   */
  protected hasBlockAddButton(): boolean {
    const hasIt: boolean = this.isDataFound() && this.creditLocaleForm.invalid;
    return hasIt;
  }

  /**
   * Indicates if it has to display the credit locale form.
   *
   * @returns boolean
   */
  protected hasDisplayCreditLocaleForm(): boolean {
    const hasIt: boolean = this.creditType === CreditType.DUBBING && this.hasWritePrivilege();
    return hasIt;
  }

  /**
   * Indicates if it has to display the localization list.
   *
   * @returns boolean
   */
  protected hasDisplayData(): boolean {
    const hasIt: boolean =
      this.isDataFound() && _isObject(this.creditsCollection) && this.creditsCollection.collection.length > 0;

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking status error for the given credit.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected hasDisplayLockingStatusErrorFor(credit: CreditLocale): boolean {
    const hasIt: boolean =
      this.creditType === CreditType.TRANSLATED &&
      this.translatedLockingStatus.has(credit.getLocale()) &&
      this.translatedLockingStatus.get(credit.getLocale()).status === DataStatusEnum.error;

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking status for the given credit.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected hasDisplayLockingStatusFor(credit: CreditLocale): boolean {
    const hasIt: boolean =
      this.creditType === CreditType.DUBBING ||
      (
        this.translatedLockingStatus.has(credit.getLocale()) &&
        this.translatedLockingStatus.get(credit.getLocale()).status === DataStatusEnum.dataFound
      );

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking status spinner for the given credit.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected hasDisplaySpinnerFor(credit: CreditLocale): boolean {
    const hasIt: boolean =
      this.creditType === CreditType.TRANSLATED &&
      !this.translatedLockingStatus.has(credit.getLocale());

    return hasIt;
  }

  /**
   * Indicates if it has to display the no localization message.
   *
   * @returns boolean
   */
  protected hasDisplayNoData(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      _isObject(this.creditsCollection) &&
      this.creditsCollection.collection.length === 0;

    return hasIt;
  }

  /**
   * Indicates if the current user has write privilege.
   *
   * @returns boolean
   */
  protected hasWritePrivilege(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.credits.path, [ActionTypeEnum.write]);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.collectionManagerSubscription = new Subscription();
    this.localesLockingStatusSubscription = new Subscription();
    this.localesSubscription = new Subscription();
    this.selectEvent = new EventEmitter();
    this.translatedLockingStatus = new Map();

    this.setupCollectionManager();
  }

  /**
   * Indicates if the current values already exists in the current credit collection.
   *
   * @param languageId number
   * @param territoryId number
   * @returns boolean
   */
  protected isAlreadyInCreditCollection(languageId: number, territoryId: number): boolean {
    const isIt: boolean = this.creditsCollection.rawCollection.some(
      (item: CreditLocale) => {
        return (
          languageId === item.getLanguage().id &&
          territoryId === item.getTerritory()[0].id
        );
      }
    );

    return isIt;
  }

  /**
   * Indicates if the given credit locale is the current selection.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected isSelectedCredit(credit: CreditLocale): boolean {
    const isIt: boolean =
      _isObject(this.selectedCredit) &&
      credit.getLanguage().id === this.selectedCredit.getLanguage().id &&
      credit.isTerritoryEqualsTo(this.selectedCredit.getTerritory());

    return isIt;
  }

  /**
   * Maps the given localizations.
   *
   * @param localizations any[]
   * @returns CreditLocale[]
   */
  protected mapTitleLocales(localizations: any[]): CreditLocale[] {
    const titleCreditLocales: CreditLocale[] = new Array();

    localizations.forEach(
      (localization: any) => {
        const territories: Country[] = new Array();

        localization.territory.forEach(
          (id: number) => {
            territories.push(this.listProvider.getTerritoryById(id));
          }
        );

        const data: any = {
          language: this.listProvider.getLanguageById(localization.language),
          territory: territories,
          locale: localization.locale
        };

        const alreadyAdded: boolean = titleCreditLocales.some(
          (credit: CreditLocale) =>
            credit.getLanguage().id === data.language.id &&
            credit.isTerritoryEqualsTo(data.territory)
        );

        if (!alreadyAdded) {
          titleCreditLocales.push(new CreditLocale(data));
        }
      }
    );

    return titleCreditLocales;
  }

  /**
   * Refresh the locking status of the current list.
   *
   * @returns void
   */
  protected refreshLockingStatus(): void {
    const credits: CreditLocale[] = this.collectionManager.getCollection('credits').rawCollection;
    this.fetchCreditLockingStatuses(credits);
  }

  /**
   * Resets the filter input.
   *
   * @returns void
   */
  protected resetFilter(): void {
    this.filterForm.get('query').setValue('');
  }

  /**
   * Retrieves the error reason for the given credit.
   *
   * @param credit CreditLocale
   * @returns string
   */
  protected retrieveErrorReasonFor(credit: CreditLocale): string {
    let errorReason: string;

    if (this.translatedLockingStatus.has(credit.getLocale())) {
      errorReason = this.translatedLockingStatus.get(credit.getLocale()).error;
    }

    return errorReason;
  }

  /**
   * Emits the select event with the selected credit.
   *
   * @param selected CreditLocale
   * @returns void
   */
  protected selectCredit(selected: CreditLocale): void {
    this.selectedCredit = selected;
    this.selectEvent.emit(selected);
  }

  /**
   * Set up the BoltStore for form default values.
   *
   * @return void
   */
  protected setupBoltStore(): void {
    this.boltStore = {
      formDefaultValues: {
        territory: 0
      }
    };
  }

  /**
   * Set up the collection manager in order to be used with credit locales.
   *
   * @returns void
   */
  protected setupCollectionManager(): void {
    this.collectionManager.setCollections([
      {
        name: 'credits',
        collection: [],
        sorting: [],
        paginate: true,
        pagination: {
          page_size: this.appConfig.get('ux.dataTables.pageSize', 20, ConfigurationTypeEnum.number)
        },
      }
    ]);

    this.collectionManagerSubscription = this.collectionManager.getCollectionHandler().subscribe(
      (collection: CollectionManagerCollectionInterface) => {
        if (collection.name === 'credits') {
          this.creditsCollection = collection;
        }
      }
    );
  }

  /**
   * Set up the filter form.
   *
   * @returns void.
   */
  protected setupFilter(): void {
    this.filterForm = this.formBuilder.group({
      query: [undefined]
    });

    this.filterForm.valueChanges.pipe(debounceTime(200)).subscribe(
      (value: any) => {
        const words = value.query ? value.query.trim().split(' ') : [];

        this.collectionManager.filterBy('credits', words, ['language', 'territory']);
      }
    );
  }

  /**
   * Set up the form.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.creditLocaleForm = this.formBuilder.group({
      language: [
        undefined,
        Validators.compose([
          Validators.required
        ])
      ],
      territory: [
        this.boltStore.formDefaultValues.territory,
        Validators.compose([
          Validators.required
        ])
      ],
    });
  }

  /**
   * Updates the current credit value in the current collection.
   *
   * @returns void
   */
  protected updateCreditLocale(): void {
    this.creditsCollection.rawCollection.map(
      (item: CreditLocale) => {
        if (
          this.creditLocaleUpdated.getLanguage().id === item.getLanguage().id &&
          item.isTerritoryEqualsTo(this.creditLocaleUpdated.getTerritory())
        ) {
          item.setLockingStatus(this.creditLocaleUpdated.getLockingStatus());
        }
      }
    );

    this.collectionManager.setCollectionItems('credits', this.creditsCollection.rawCollection);
  }
}
