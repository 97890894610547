import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Account, Country, Language, ProductType, StormListInterface, StormLists, StormListType } from '@bolt/ui-shared/master-data';
import { Observable, Observer } from 'rxjs';

import {
  clone as _clone,
  cloneDeep as _cloneDeep,
  filter as _filter,
  find as _find,
  groupBy as _groupBy,
  isArray as _isArray,
  isUndefined as _isUndefined,
  map as _map,
  merge as _merge,
  reject as _reject,
  isNumber as _isNumber,
  isEmpty as _isEmpty
} from 'lodash';

import _ from 'lodash';

import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { Feature, FeatureInterface } from '../../models/feature.model';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { TitleMetadataInterface } from '../../models/title-metadata.model';
import { Title, TitleType } from '../../models/title.model';


export interface ProductMetadataSortByInterface {
  property: string;
  reverse: boolean;
  order: number;
}

export interface ProductLayoutAttributeRuleCrudLocalePatternsInterface {
  CREATE: boolean;
  READ: boolean;
  UPDATE: boolean;
  DELETE: boolean;
}

export interface ProductLayoutAttributeGroupInterface {
  key: string;
  name: string;
  icon: string;
  newAttributeLabel: string;
  attributes: string[];
  rules: {
    [propName: string]: {
      crudLocalePatterns: ProductLayoutAttributeRuleCrudLocalePatternsInterface;
    }
  };
  defaultSelectedLocale: string;
  manager: {
    localeRootPattern: string;
    attributes: {
      [propName: string]: {
        default?: any;
        multiple?: boolean;
        disabled?: boolean;
        allowAll?: boolean;
        minLength?: number;
        maxLength?: number;
        required?: boolean;
      }
    };
  };
}

export interface ProductLayoutInterface {
  productType: string;
  titleLevelData: {
    attributes: string[]
  };
  attributeGroups: {
    languageSpecific: ProductLayoutAttributeGroupInterface,
    territorySpecific: ProductLayoutAttributeGroupInterface,
    accountSpecific: ProductLayoutAttributeGroupInterface
  };
  attributeNames: {
    titleLevelData: string[],
    metadata: string[]
  };
}


@Injectable()
// TODO For removing this and app-product-layout.config.json we need to improve the title level data component.
export class ProductLayoutHelper {
  public layoutConfig: any;
  protected productTypeLayouts: ProductLayoutInterface[] = [];

  constructor(
    protected stormListsProvider: StormListsProvider,
    protected appConfig: AppConfigProvider,
    protected entityMapper: EntityMapperHelper
  ) {
    this.layoutConfig = require('./../../../../../../config/app-product-layout.config.json');
    this.setupProductLayouts();
  }

  setupProductLayouts() {
    Object.keys(this.layoutConfig.productLayout).forEach(productType => {

      this.productTypeLayouts.push(_merge(
        { productType: productType },
        this.layoutConfig.common,
        this.layoutConfig.productLayout[productType]
      ));
    });
  }

  getProductLayout(productType: TitleType): ProductLayoutInterface {
    const productLayout = _find(this.productTypeLayouts, {
      productType: productType.toString().toLowerCase()
    });

    if (!productLayout) {
      throw new Error('The ' + productType + ' Product Type layout does not exist.');
    }

    return productLayout;
  }

  getProductLayoutTitleLevelAttributeGroup(productType: TitleType) {
    return this.getProductLayout(productType).titleLevelData;
  }

  getProductLayoutAttributeGroups(productType: TitleType) {
    return this.getProductLayout(productType).attributeGroups;
  }

  getProductLayoutAttributeGroup(productType: TitleType, groupName: string) {
    return this.getProductLayoutAttributeGroups(productType)[groupName];
  }

  getProductLayoutAttributeNames(productType: TitleType) {
    return this.getProductLayout(productType).attributeNames;
  }

  getProductLayoutAttributeGroupManager(productType: TitleType, groupName: string) {
    return this.getProductLayoutAttributeGroups(productType)[groupName].manager;
  }

  /**
   * Maps the attributes for the given metadata collection.
   *
   * @param metadataCollection TitleMetadataInterface|TitleMetadataInterface[]
   * @returns Observable<TitleMetadataInterface[]>
   */
  mapAttributes(
    metadataCollection: Title | Title[],
    fillingMetadataType: string = 'all'
  ): Observable<Title[]> {
    if (!_isArray(metadataCollection)) {
      metadataCollection = [<Title>metadataCollection];
    }

    let obsResponse: Observable<Title[]>;

    switch (fillingMetadataType) {
      case LocalizationSpecific.LANGUAGE.toString():
        obsResponse = this.mapAttributesForLanguages(<Title[]>metadataCollection);
        break;
      case LocalizationSpecific.TERRITORY.toString():
        obsResponse = this.mapAttributesForTerritories(<Title[]>metadataCollection);
        break;
      case LocalizationSpecific.ACCOUNT.toString():
        obsResponse = this.mapAttributesForAccount(<Title[]>metadataCollection);
        break;
      default:
        obsResponse = this.mapAttributesForAll(<Title[]>metadataCollection);
        break;
    }

    return obsResponse;
  }

  /**
   * Maps the attributes for an Account in a Product.
   *
   * @param productMetadata any
   * @param metaAccount StormListInterface
   * @returns void
   */
  mapMetaAccount(productMetadata: any, metaAccount: StormListInterface): void {
    try {
      productMetadata.account = (<[number]>productMetadata.account).map(
        account => {
          return metaAccount.getItem(account).value;
        }
      );
    } catch (e) { }
  }

  /**
   * Maps the attributes for a Territory in a Product.
   *
   * @param productMetadata any
   * @param metaTerritory StormListInterface
   * @returns void
   */
  mapMetaTerritory(productMetadata: any, metaTerritory: StormListInterface): void {
    try {
      productMetadata.territory = (<[number]>productMetadata.territory).map(
        territory => {
          return metaTerritory.getItem(territory).value;
        }
      );
    } catch (e) { }
  }

  mapMetaPrimaryProductAssociation(productMetadata: any, metaPrimaryProductAssociation: StormListInterface): void {
    try {
      productMetadata.primaryProductAssociation =
        metaPrimaryProductAssociation.getItem(productMetadata.primaryProductAssociation).value;
    } catch (e) { }
  }

  mapMetaSecondaryProductAssociation(productMetadata: any, metaSecondaryProductAssociation: StormListInterface): void {
    try {
      productMetadata.secondaryProductAssociation =
        metaSecondaryProductAssociation.getItem(productMetadata.secondaryProductAssociation).value;
    } catch (e) { }
  }

  /**
   * Maps the attributes for a Type in a Product.
   *
   * @param productMetadata any
   * @param metaProductType StormListInterface
   * @returns void
   */
  mapMetaProductType(productMetadata: any, metaProductType: StormListInterface): void {
    try {
      productMetadata.productType = (<[number]>productMetadata.productType).map(
        productType => {
          return metaProductType.getItem(productType).value;
        }
      );
    } catch (e) { }
  }

  /**
   * Maps the attributes for a Type in a Product.
   *
   * @param productMetadata any
   * @param metaLanguages StormListInterface
   * @returns void
   */
  mapMetaLanguages(productMetadata: any, metaLanguages: StormListInterface): void {
    try {
      productMetadata.language = metaLanguages.getItem(Number(productMetadata.language)).value;
    } catch (e) { }
  }

  /**
   * Maps the attributes for a Rating in a Product.
   *
   * @param productMetadata any
   * @param metaRating StormListInterface
   * @returns void
   */
  mapMetaRatingSystem(
    productMetadata: any,
    metaRating: StormListInterface,
    metaRatingSystem: StormListInterface,
    property = 'ratingId'
  ): void {
    try {
      (<Feature>productMetadata)[property] =
        (<[number]>(<Feature>productMetadata)[property]).map(
          ratingId => {
            const rating = metaRating.getItem(ratingId).value;

            if (_isNumber(rating.ratingSystemId)) {
              rating.ratingSystemId = metaRatingSystem.getItem(rating.ratingSystemId).value;
            } else {
              rating.ratingSystemId = metaRatingSystem.getItem(rating.ratingSystemId.id).value;
            }
            return rating;
          });
    } catch (e) { }
  }
  /**
   * Maps the attributes for a Rating Reason in a Product.
   *
   * @param productMetadata any
   * @param metaRatingSystemReason StormListInterface
   * @returns void
   */
  mapMetaRatingSystemReasonId(
    productMetadata: any,
    metaRatingSystemReason: StormListInterface,
    property = 'ratingSystemReasonId'
  ): void {
    try {
      (<FeatureInterface>productMetadata)[property] =
        (<[number]>(<FeatureInterface>productMetadata)[property]).map(
          ratingSystemReasonId => metaRatingSystemReason
            .getItem(ratingSystemReasonId).value
        );
    } catch (e) { }
  }

  /**
   * Maps the attributes for a Genre in a Product.
   *
   * @param productMetadata any
   * @param metaGenre StormListInterface
   * @returns void
   */
  mapMetaGenre(productMetadata: any, metaGenre: StormListInterface): void {
    try {
      (<FeatureInterface>productMetadata).genreId =
        (<[number]>(<FeatureInterface>productMetadata).genreId).map(
          genreId => {
            return metaGenre.getItem(genreId).value;
          }
        );
    } catch (e) { }
  }

  /**
   * Maps the attributes for a Functional Metadata in a Product.
   *
   * @param productMetadata any
   * @param metaFunctionalMetadata StormListInterface
   * @returns void
   */
  mapMetaFunctionalMetadata(productMetadata: any, metaFunctionalMetadata: StormListInterface): void {
    try {
      (<FeatureInterface>productMetadata).functionalMetadata =
        (<[number]>(<FeatureInterface>productMetadata).functionalMetadata).map(
          functionalMetadata => {
            return metaFunctionalMetadata.getItem(functionalMetadata).value;
          }
        );
    } catch (e) { }
  }

  getLocaleFromLocaleIds(locale: Locale | Title) {
    return new Observable(observer => {

      let productMetadata: Title;

      if (locale instanceof Title) {
        productMetadata = Object.assign(new Object(), locale);
      } else {
        productMetadata = <Title>{
          language: <number>locale.language,
          territory: locale.territory,
          productType: locale.productType,
          account: locale.account
        };
      }

      this.mapAttributes([productMetadata]).subscribe(

        mapProductMetadata => {

          const pm = mapProductMetadata[0];

          const territory = (<Country[]>pm.territory).map(
            item => {
              if (item.id === 0) {
                return '*';
              }
              return item.iso31661;
            }
          ).join(',');

          const productType = (<ProductType[]>pm.productType).map(
            item => {
              if (item.id === 0) {
                return '*';
              }
              return item.code;
            }
          ).join(',');

          const account = (<Account[]>pm.account).map(
            item => {
              if (item.id === 0) {
                return '*';
              }
              return item.code;
            }
          ).join(',');

          const stringLocale = [
            (<Language>pm.language).localeLanguage,
            territory,
            productType,
            account
          ].join('_');

          observer.next(stringLocale);
          observer.complete();
        }

      );

    });
  }

  /**
   * Sort the Product Metadata collection with the given sortBy criteria
   *
   * @param  productType TitleType
   * @param  productMetadataCollection ProductMetadataInterface[]
   * @param  sortBy ProductMetadataSortByInterface[]
   * @param  groupBy string
   * @returns ProductMetadataInterface[]
   */
  sortProductMetadata(
    productType: TitleType,
    productMetadataCollection: TitleMetadataInterface[],
    sortBy: ProductMetadataSortByInterface[] = [],
    groupBy?: string
  ): TitleMetadataInterface[] {

    if (!sortBy.length && groupBy) {

      const firstItemsCriteria = {
        locale: this.getProductLayoutAttributeGroup(productType, groupBy).defaultSelectedLocale
      };
      const firstItems = _filter(productMetadataCollection, firstItemsCriteria);
      const res = <any>[...firstItems, ..._reject(productMetadataCollection, firstItemsCriteria)];

      return res;
    }

    let _productMetadataCollection = _(productMetadataCollection).chain();
    const sorting = _map(sortBy, _clone);

    sorting.reverse().forEach((sort: any) => {

      _productMetadataCollection = _productMetadataCollection.sortBy((o) => {
        if (_isArray(o[sort.property])) {
          const all = _(o[sort.property]).filter({ id: 0 });
          return ((all.size() ? '0' : '').toString() +
            all.join('_') +
            _(o[sort.property]).reject({ id: 0 }).sortBy('value').join('_')).toUpperCase();
        }
        return (o[sort.property].id === 0 ? '0' : '') + o[sort.property].toString();
      });

      if (sort.reverse) {
        _productMetadataCollection = _productMetadataCollection.reverse();
      }

    });

    return _productMetadataCollection.value();
  }

  /**
   * Indicates if the given products metadata has the same attributes for their given group.
   *
   * @param productType TitleType
   * @param productMetadata TitleMetadataInterface
   * @param groupName string
   * @param validateAll boolean
   * @returns boolean
   */
  hasGroupAttributes(
    productType: TitleType,
    productMetadata: TitleMetadataInterface,
    groupName: string,
    validateAll: boolean = false
  ): boolean {
    let attributes: string[] = this.getProductLayoutAttributeGroup(productType, groupName).attributes;
    let hasIt: boolean;
    let matchesCount = 0;
    let ratingAttributes: string[] = [];
    let attributeValue;

    ratingAttributes = this.getProductLayoutAttributeGroup(productType, groupName).ratingAttributes;

    attributes = attributes.concat(ratingAttributes);

    attributes.forEach(
      (attribute: string) => {
        attributeValue = productMetadata[attribute];
        if (
          (!_isUndefined(attributeValue) &&  !_isArray(attributeValue)) ||
          (!_isUndefined(attributeValue) && !_isEmpty(attributeValue))
        ) {
          matchesCount++;
        }
      }
    );

    if (validateAll) {
      hasIt = (attributes.length === matchesCount);
    } else {
      hasIt = (matchesCount > 0);
    }

    return hasIt;
  }

  /**
   * Validates a given attribute key to be enabled/displayed
   *
   * @param attributeGroup ProductLayoutAttributeGroupInterface
   * @param key string
   * @param titleMetadata TitleMetadataInterface
   * @returns ProductLayoutAttributeRuleCrudLocalePatternsInterface
   */
  getCrudAttributePermissions(
    attributeGroup: ProductLayoutAttributeGroupInterface,
    key: string,
    titleMetadata: TitleMetadataInterface
  ): ProductLayoutAttributeRuleCrudLocalePatternsInterface {

    const permissions = {
      CREATE: true,
      READ: true,
      UPDATE: true,
      DELETE: true,
    };

    if (
      attributeGroup.rules &&
      attributeGroup.rules[key] &&
      attributeGroup.rules[key].crudLocalePatterns
    ) {

      Object.keys(attributeGroup.rules[key].crudLocalePatterns).forEach(operation => {
        permissions[operation] = new RegExp(
          attributeGroup.rules[key].crudLocalePatterns[operation],
          'ig'
        ).test(titleMetadata.locale);
      });

    }

    return permissions;

  }

  /**
   * Returns an observable of a strings array having the Territory Region names | Country.
   * A Territory Region name will be returned if ALL of the countries that belongs to a Region are present.
   * If not, the Country name will be returned.
   *
   * @deprecated Use EntityMapperHelper.groupTerritoriesByRegion
   * @param productMetadata ProductMetadataInterface
   * @returns Observable<any>
   */
  groupTerritoriesByRegion(productMetadata: TitleMetadataInterface): Observable<any> {
    return this.entityMapper.groupTerritoriesByRegion(<Country[]>productMetadata.territory);
  }

  /**
   * Maps the attributes for the given metadata collection for Languages.
   *
   * @param metadataCollection TitleMetadataInterface[]
   * @returns Observable<TitleMetadataInterface[]>
   */
  protected mapAttributesForLanguages(
    metadataCollection: Title[]
  ): Observable<Title[]> {
    const obsResponse: Observable<Title[]> = new Observable(
      (observer: Observer<any>) => {
        this.stormListsProvider.getLists().subscribe(
          (lists: StormLists) => {
            const metaAccount = lists.getList(StormListType.account);
            const metaTerritory = lists.getList(StormListType.territory);
            const metaProductType = lists.getList(StormListType.productType);
            const metaLanguages = lists.getList(StormListType.language);

            (<Title[]>metadataCollection).map(
              (productMetadata: Title) => {
                if (productMetadata.localeObject.type.isLanguage()) {

                  // TODO: Review this backup. Keep the original property values as a copy.
                  // productMetadata.originalData = _cloneDeep(productMetadata);

                  this.mapMetaLanguages(productMetadata, metaLanguages);
                  this.mapMetaAccount(productMetadata, metaAccount);
                  this.mapMetaTerritory(productMetadata, metaTerritory);
                  this.mapMetaProductType(productMetadata, metaProductType);
                }
              }
            );

            observer.next(metadataCollection);
            observer.complete();
          }
        );
      }
    );

    return obsResponse;
  }

  /**
   * Maps the attributes for the given metadata collection for Territories.
   *
   * @param metadataCollection TitleMetadataInterface[]
   * @returns Observable<TitleMetadataInterface[]>
   */
  protected mapAttributesForTerritories(
    metadataCollection: Title[]
  ): Observable<Title[]> {
    const obsResponse: Observable<Title[]> = Observable.create(
      (observer: Observer<any>) => {
        this.stormListsProvider.getLists().subscribe(
          (lists: StormLists) => {
            const metaAccount = lists.getList(StormListType.account);
            const metaLanguages = lists.getList(StormListType.language);
            const metaTerritory = lists.getList(StormListType.territory);
            const metaProductType = lists.getList(StormListType.productType);
            const metaGenre = lists.getList(StormListType.genre);
            const metaFunctionalMetadata = lists.getList(StormListType.functionalMetadata);
            const metaRating = lists.getList(StormListType.rating);
            const metaRatingSystem = lists.getList(StormListType.ratingSystem);
            const metaRatingSystemReason = lists.getList(StormListType.ratingSystemReason);

            (<Title[]>metadataCollection).map(
              (productMetadata: Title) => {
                if (productMetadata.localeObject.type.isTerritory()) {

                  // TODO: Review this backup. Keep the original property values as a copy.
                  // productMetadata.originalData = _cloneDeep(productMetadata);

                  this.mapMetaAccount(productMetadata, metaAccount);
                  this.mapMetaGenre(productMetadata, metaGenre);
                  this.mapMetaFunctionalMetadata(productMetadata, metaFunctionalMetadata);
                  this.mapMetaLanguages(productMetadata, metaLanguages);
                  this.mapMetaProductType(productMetadata, metaProductType);
                  this.mapMetaRatingSystem(productMetadata, metaRating, metaRatingSystem, 'ratingId');
                  this.mapMetaRatingSystem(productMetadata, metaRating, metaRatingSystem, 'homeEntRatingId');
                  this.mapMetaRatingSystemReasonId(productMetadata, metaRatingSystemReason, 'ratingSystemReasonId');
                  this.mapMetaRatingSystemReasonId(productMetadata, metaRatingSystemReason, 'homeEntRatingSystemReasonId');
                  this.mapMetaTerritory(productMetadata, metaTerritory);
                }
              }
            );

            observer.next(metadataCollection);
            observer.complete();
          }
        );
      }
    );

    return obsResponse;
  }

  /**
   * Maps the attributes for the given metadata collection for Accounts.
   *
   * @param metadataCollection TitleMetadataInterface[]
   * @returns Observable<TitleMetadataInterface[]>
   */
  protected mapAttributesForAccount(
    metadataCollection: Title[]
  ): Observable<Title[]> {
    const obsResponse: Observable<Title[]> = Observable.create(
      (observer: Observer<any>) => {
        this.stormListsProvider.getLists().subscribe(
          (lists: StormLists) => {
            const metaAccount = lists.getList(StormListType.account);
            const metaLanguages = lists.getList(StormListType.language);
            const metaGenre = lists.getList(StormListType.genre);
            const metaFunctionalMetadata = lists.getList(StormListType.functionalMetadata);
            const metaTerritory = lists.getList(StormListType.territory);
            const metaPrimaryProductAssociation = lists.getList(StormListType.primaryProductAssociation);
            const metaProductType = lists.getList(StormListType.productType);
            const metaSecondaryProductAssociation = lists.getList(StormListType.secondaryProductAssociation);

            (<Title[]>metadataCollection).map(
              (productMetadata: Title) => {
                if (productMetadata.localeObject.type.isAccount()) {

                  // TODO: Review this backup. Keep the original property values as a copy.
                  // productMetadata.originalData = _cloneDeep(productMetadata);

                  this.mapMetaAccount(productMetadata, metaAccount);
                  this.mapMetaGenre(productMetadata, metaGenre);
                  this.mapMetaFunctionalMetadata(productMetadata, metaFunctionalMetadata);
                  this.mapMetaLanguages(productMetadata, metaLanguages);
                  this.mapMetaPrimaryProductAssociation(productMetadata, metaPrimaryProductAssociation);
                  this.mapMetaSecondaryProductAssociation(productMetadata, metaSecondaryProductAssociation);
                  this.mapMetaProductType(productMetadata, metaProductType);
                  this.mapMetaTerritory(productMetadata, metaTerritory);
                }
              }
            );

            observer.next(metadataCollection);
            observer.complete();
          }
        );
      }
    );

    return obsResponse;
  }

  /**
   * Maps the attributes for the given metadata collection.
   *
   * @param metadataCollection TitleMetadataInterface[]
   * @returns Observable<TitleMetadataInterface[]>
   */
  protected mapAttributesForAll(
    metadataCollection: Title[]
  ): Observable<Title[]> {
    const obsResponse: Observable<Title[]> = Observable.create(
      (observer: Observer<any>) => {
        this.stormListsProvider.getLists().subscribe(
          (lists: StormLists) => {
            const metaAccount = lists.getList(StormListType.account);
            const metaTerritory = lists.getList(StormListType.territory);
            const metaProductType = lists.getList(StormListType.productType);
            const metaGenre = lists.getList(StormListType.genre);
            const metaFunctionalMetadata = lists.getList(StormListType.functionalMetadata);
            const metaPrimaryProductAssociation = lists.getList(StormListType.primaryProductAssociation);
            const metaSecondaryProductAssociation = lists.getList(StormListType.secondaryProductAssociation);
            const metaRating = lists.getList(StormListType.rating);
            const metaRatingSystem = lists.getList(StormListType.ratingSystem);
            const metaRatingSystemReason = lists.getList(StormListType.ratingSystemReason);
            const metaLanguages = lists.getList(StormListType.language);

            (<Title[]>metadataCollection).map(
              (productMetadata: Title) => {
                // TODO: Review this backup. Keep the original property values as a copy.
                // productMetadata.originalData = _cloneDeep(productMetadata);

                this.mapMetaAccount(productMetadata, metaAccount);
                this.mapMetaTerritory(productMetadata, metaTerritory);
                this.mapMetaProductType(productMetadata, metaProductType);
                this.mapMetaLanguages(productMetadata, metaLanguages);
                this.mapMetaRatingSystem(productMetadata, metaRating, metaRatingSystem, 'ratingId');
                this.mapMetaRatingSystem(productMetadata, metaRating, metaRatingSystem, 'homeEntRatingId');
                this.mapMetaRatingSystemReasonId(productMetadata, metaRatingSystemReason, 'ratingSystemReasonId');
                this.mapMetaRatingSystemReasonId(productMetadata, metaRatingSystemReason, 'homeEntRatingSystemReasonId');
                this.mapMetaGenre(productMetadata, metaGenre);
                this.mapMetaFunctionalMetadata(productMetadata, metaFunctionalMetadata);
                this.mapMetaPrimaryProductAssociation(productMetadata, metaPrimaryProductAssociation);
                this.mapMetaSecondaryProductAssociation(productMetadata, metaSecondaryProductAssociation);
              }
            );

            observer.next(metadataCollection);
            observer.complete();
          }
        );
      }
    );

    return obsResponse;
  }
}
