import { Component, Input } from '@angular/core';

import {
  isObject as _isObject,
  isArray as _isArray,
  replace as _replace,
  capitalize as _capitalize
} from 'lodash';

import { EditHistoryMetadata } from '../bolt-edit-history/bolt-edit-history.component';


@Component({
  selector: 'bolt-edit-history-header',
  template: require('./bolt-edit-history-header.html'),
  styles: [require('./bolt-edit-history-header.scss')]
})
export class BoltEditHistoryHeaderComponent {
  @Input() metadata: EditHistoryMetadata;
  @Input() isRoot: boolean;
  @Input() isTitle: boolean;

  constructor() { }

  getId(): any {
    return this.isTitle ? this.metadata.radarProductId : this.metadata.imdb;
  }

  getType(): string {
    return _capitalize(this.metadata?.type?.toString());
  }
}
