import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { omit as _omit, isUndefined as _isUndefined } from 'lodash';

import { TitleEditHistory } from '@bolt/ui-shared/title';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { TypeEnum as CreditType } from 'app/modules/credits/models/wizard/candidate/type/type.enum';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';
import { TitleType } from 'app/modules/title/models/title.model';

import {
  StormServiceResponseSingle
} from 'app/modules/common/services/storm-service-response-single';


@Injectable()
export class EditHistoryService extends BoltAbstractService {
  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription for fetching the title localization edit history.
   *
   * @param locale string
   * @param type TitleType
   * @param id number
   * @returns Observable<TitleEditHistory[]>
   */
  fetchLocalizationEditHistory(
    locale: string,
    type: TitleType | CreditType,
    id: number,
    archivedHistory: boolean
  ): Observable<TitleEditHistory[]> {
    const params: any = {
      '{locale}': locale,
      '{type}': type,
      '{id}': id
    };

    const queryParams = new UrlSearchParams();
    queryParams.set('archivedHistory', archivedHistory.toString());

    const url: string = this.generateUrl(
      'historyService.fetchLocalizationEditHistory.endpoint',
      params,
      queryParams
    );

    const obs: Observable<TitleEditHistory[]> = this.doGetRequestAsObservable(
      { url: url, checkType: CheckTypeEnum.array }
    ).pipe(
      map(
        (successResponse: StormServiceResponseSingle) => {
          return this.mapEditHistoryResponse(successResponse);
        }
      )
    );

    return obs;
  }

  /**
   * Returns a observable for fetching edit history with archivedHistory.
   *
   * @param type TitleType | CreditType
   * @param id number
   * @param archivedHistory boolean
   * @returns Observable<TitleEditHistory[]>
   */
  fetchRootEditHistory(
    type: TitleType | CreditType,
    id: number,
    archivedHistory: boolean
  ): Observable<TitleEditHistory[]> {
    const params: any = {
      '{type}': type,
      '{id}': id,
      '{archivedHistory}': archivedHistory
    };

    const queryParams = new UrlSearchParams();
    queryParams.set('archivedHistory', archivedHistory.toString());

    const url: string = this.generateUrl(
      'historyService.fetchEditHistory.endpoint',
      params,
      queryParams
    );

    const obs: Observable<TitleEditHistory[]> = this.doGetRequestAsObservable(
      { url: url, checkType: CheckTypeEnum.array }
    ).pipe(
      map(
        (successResponse: StormServiceResponseSingle) => {
          return this.mapEditHistoryResponse(successResponse);
        }
      )
    );

    return obs;
  }

  /**
   * Maps the service response into a TitleEditHistory array
   *
   * @param successResponse StormServiceResponseSingle
   * @returns TitleEditHistory[]
   */
  protected mapEditHistoryResponse(successResponse: StormServiceResponseSingle): TitleEditHistory[] {
    const response: TitleEditHistory[] = new Array();

    successResponse.item.forEach((raw: any) => {
      response.push(new TitleEditHistory(raw));
    });

    return response;
  }
}
