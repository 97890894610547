import { Component, Input, OnInit } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { FunctionalMetadataService } from '@bolt/ui-shared/title';
import { RatingType } from '@bolt/ui-shared/master-data';
import { get as _get, isUndefined, isEmpty } from 'lodash';

import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { Title } from '../../models/title.model';
import { BoltRatingService } from '../../services/bolt-rating.service';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { BoltSimpleRating } from './bolt-rating.class';
import { BoltTitleTerritoryLocalizationDetailsComponent } from '../bolt-title-territory-localization-details/bolt-title-territory-localization-details.component';
import { Feature } from '../../models/feature.model';
import { Episode } from '../../models/episode.model';


@Component({
  selector: 'bolt-rating-view',
  template: require('./bolt-rating-view.component.html'),
  styles: [require('./bolt-rating-view.component.scss')]
})
export class BoltRatingViewComponent extends BoltTitleTerritoryLocalizationDetailsComponent implements OnInit {
  @Input() localization: Title;
  @Input() useComputed: boolean;
  @Input() allowActions: boolean;

  ratings = [];
  ratingReason: BoltSimpleRating[] = [];
  additionalRatingReason: BoltSimpleRating[] = [];
  ratingActionsAttributes: string[] = [];
  ratingReasonActionsAttributes: string[] = [];
  additionalRatingReasonActionsAttributes: string[] = [];
  ratingReasonHeaders = [];
  ratingHeaders = [];

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected listProvider: StormListsProvider,
    protected functionalMetadataService: FunctionalMetadataService,
    protected boltRatingService: BoltRatingService
  ) {
    super(appConfigurationManager, listProvider, functionalMetadataService, boltRatingService);
  }

  ngOnInit(): void {
    const {
      ratings, ratingReason, additionalRatingReason,
      ratingHeaders, ratingReasonHeaders,
    } = this.boltRatingService.getRatingsInfo(this.localization);

    this.ratings = ratings;
    this.ratingReason = ratingReason;
    this.additionalRatingReason = additionalRatingReason;
    this.ratingReasonHeaders = ratingReasonHeaders;
    this.ratingHeaders = ratingHeaders;
    this.ratingActionsAttributes = [FeatureAttributes.ratingId, FeatureAttributes.homeEntRatingId];
    this.additionalRatingReasonActionsAttributes = [FeatureAttributes.ratingSystemReasonId, FeatureAttributes.homeEntRatingSystemReasonId];
    this.ratingReasonActionsAttributes = [FeatureAttributes.ratingReason, FeatureAttributes.homeEntRatingReason];
  }

  isEmpty(fieldName: string): boolean {
    const hasIt: boolean =
      isUndefined(this.localization.localizedFields[fieldName]) ||
      (
        this.useComputed &&
        this.localization.isComputedField(fieldName)
      ) ||
      isEmpty(this.localization[fieldName]);

    return hasIt;
  }

  /**
   * Overrides the hasUseLightGreyFor to account for empty arrays.
   *
   * @param attributeName string
   * @returns boolean
   */
  protected hasUseLightGreyFor(attributeName: string): boolean {
    let hasIt = this.isEmpty(attributeName);

    if (attributeName === this.featureAttributes.ratingId && this.isLanguageProductTypeAndAccountAll) {
      hasIt = hasIt && this.isEmpty(this.featureAttributes.homeEntRatingId);
    } else if (attributeName === this.featureAttributes.ratingSystemReasonId && this.isLanguageProductTypeAndAccountAll) {
      hasIt = hasIt && this.isEmpty(this.featureAttributes.homeEntRatingSystemReasonId);
    } else if (attributeName === this.featureAttributes.ratingReason && this.isLanguageProductTypeAndAccountAll) {
      hasIt = hasIt && this.isEmpty(this.featureAttributes.homeEntRatingReason);
    }

    return hasIt;
  }

  /**
   * Indicates if the current localization has an authoritative rating.
   *
   * @returns boolean
   */
  protected isAuthoritativeRating(): boolean {
    return (<Feature | Episode>this.localization).ratingType === RatingType.AUTHORITATIVE;
  }
}
