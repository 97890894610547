import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import {
  isArray as _isArray, isObject as _isObject, isString as _isString, isNull as _isNull, isBoolean as _isBoolean,
  isNumber as _isNumber
} from 'lodash';

import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { CheckTypeEnum } from './check-type.enum';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';

// TODO replace ErrorHelper when BOLTM-3129 is done.
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) { }

  /**
   * Resturns a new ErrorHelper for an invalid response.
   *
   * @returns ErrorHelper
   */
  protected createInvalidResponseError(): ErrorHelper {
    return new ErrorHelper('Invalid response detected.', ErrorHelper.INVALID_STATUS_CODE);
  }

  /**
   * Returns a default catch on the given error.
   *
   * @param error HttpErrorResponse
   * @returns Observable<any>
   */
  protected defaultCatchOn(error: HttpErrorResponse): Observable<any> {
    const data: ErrorHelper = ErrorHelper.from(error);
    const obs: Observable<any> = throwError(data);

    return obs;
  }

  /**
   * Returns a default map on the given response.
   *
   * @param response any
   * @param checkType CheckTypeEnum
   * @returns StormServiceResponseSingle
   */
  protected defaultMapOn(response: any, checkType: CheckTypeEnum = CheckTypeEnum.object): StormServiceResponseSingle {
    const actualType: any = response;

    switch (checkType) {
      case CheckTypeEnum.boolean:
        if (_isNumber(response)) {
          response = Boolean(response);
        } else if (!_isBoolean(response)) {
          throw new ErrorHelper(`Expected a boolean for mapping the response: ${actualType}.`);
        }
      break;
      case CheckTypeEnum.array:
        if (!_isArray(response)) {
          throw new ErrorHelper(`Expected an array for mapping the response: ${actualType}.`);
        }
      break;
      case CheckTypeEnum.object:
        if (!_isObject(response)) {
          throw new ErrorHelper(`Expected an object for mapping the response: ${actualType}.`);
        }
      break;
      case CheckTypeEnum.blob:
        if (!(response instanceof Blob)) {
          throw new ErrorHelper(`Expected a blob for mapping the response: ${actualType}.`);
        }
      break;
      case CheckTypeEnum.string:
        if (!_isString(response)) {
          throw new ErrorHelper(`Expected a string for mapping the response: ${actualType}.`);
        }
      break;
      case CheckTypeEnum.null:
        if (!_isNull(response)) {
          throw new ErrorHelper(`Expected a null for mapping the response: ${actualType}.`);
        }
      break;
      default:
        throw new ErrorHelper(`Invalid check type given for mapping the response: ${actualType}.`);
    }

    const singleResponse: StormServiceResponseSingle = new StormServiceResponseSingle(response);

    return singleResponse;
  }

  /**
   * Does the given GET request and returns a subscription.
   *
   * @param request any
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  protected doGetRequest(
    request: { url: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean },
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const subs: Subscription = this.authHttp.get(request.url, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(onSuccessDo, onErrorDo);

    return subs;
  }

  /**
   * Does the given GET request and returns an observable.
   *
   * @param request any
   * @returns Observable<StormServiceResponseSingle>
   */
  protected doGetRequestAsObservable(
    request: { url: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean }
  ): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.get(request.url, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }

  /**
   * Does the given POST request and returns a subscription.
   *
   * @param request any
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  protected doPostRequest(
    request: { url: string, body: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean },
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const subs: Subscription = this.authHttp.post(request.url, request.body, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(onSuccessDo, onErrorDo);

    return subs;
  }

  /**
   * Does the given POST request and returns an observable.
   *
   * @param request any
   * @returns Observable<StormServiceResponseSingle>
   */
  protected doPostRequestAsObservable(
    request: { url: string, body: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean }
  ): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.post(request.url, request.body, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }

  /**
   * Does the given PATCH request and returns a subscription.
   *
   * @param request any
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  protected doPatchRequest(
    request: { url: string, body: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean },
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const subs: Subscription = this.authHttp.patch(request.url, request.body, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(onSuccessDo, onErrorDo);

    return subs;
  }

  /**
   * Does the given PATCH request and returns an observable.
   *
   * @param request any
   * @returns Observable<StormServiceResponseSingle>
   */
  protected doPatchRequestAsObservable(
    request: { url: string, body: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean }
  ): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.patch(request.url, request.body, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }

  /**
   * Does the given PUT request and returns a subscription.
   *
   * @param request any
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  protected doPutRequest(
    request: { url: string, body: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean },
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const subs: Subscription = this.authHttp.put(request.url, request.body, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(onSuccessDo, onErrorDo);

    return subs;
  }

  /**
   * Does the given PUT request and returns an observable.
   *
   * @param request any
   * @returns Observable<StormServiceResponseSingle>
   */
  protected doPutRequestAsObservable(
    request: { url: string, body: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean }
  ): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.put(request.url, request.body, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }

  /**
   * Does the given DELETE request and returns a subscription.
   *
   * @param request any
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  protected doDeleteRequest(
    request: { url: string, body?: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean },
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const subs: Subscription = this.authHttp.delete(request.url, request.body, request.options, request.retry).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(onSuccessDo, onErrorDo);

    return subs;
  }

  /**
   * Does the given DELETE request and returns an observable.
   *
   * @param request any
   * @returns Observable<StormServiceResponseSingle>
   */
  protected doDeleteRequestAsObservable(
    request: { url: string, body?: string, options?: any, checkType?: CheckTypeEnum, retry?: boolean }
  ): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.delete(
      request.url,
      request.body,
      request.options,
      request.retry
    ).pipe(
      map(
        (response: HttpResponse<any>) => this.defaultMapOn(response, request.checkType)
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }

  /**
   * Generates the URL form the given parameters.
   *
   * @param configKey string
   * @param colonParams object
   * @param queryParams object
   * @returns string
   */
  protected generateUrl(configKey: string, colonParams?: object, queryParams?: object): string {
    let url: string = this.appRoutes.get(configKey);

    if (colonParams) {
      Object.keys(colonParams).forEach(
        (key: string) => {
          url = url.replace(key, colonParams[key]);
        }
      );
    }

    if (queryParams) {
      const query: string = UrlSearchParams.from(queryParams).toString();

      if (query.length > 0) {
        url = `${url}?${query}`;
      }
    }

    return url;
  }
}
