import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Language } from '@bolt/ui-shared/master-data';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { reject as _reject, isObject as _isObject, orderBy as _orderBy, isUndefined as _isUndefined } from 'lodash';

import { Title } from '../../models/title.model';
import { modulesPath } from '../../../../modules/auth/services/role/modules-path';
import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { CapabilitiesManager } from '../../../../modules/auth/services/role/capabilities.manager';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { BoltTitleMetadataListBaseComponent } from '../bolt-title-metadata-list-base.component';

@Component({
  selector: 'bolt-title-metadata-list-language',
  template: require('./bolt-title-metadata-list-language.html'),
  styles: [require('../bolt-title-metadata-list/bolt-title-metadata-list.scss')]
})
export class BoltTitleMetadataListLanguageComponent extends BoltTitleMetadataListBaseComponent implements OnChanges {
  protected readonly emptyLocalizationMessage: string = 'This localization contains no data.';
  protected unlockPrivileges: Map<string, boolean> = new Map();
  protected lockPrivileges: Map<string, boolean> = new Map();
  protected localeLimit: number;
  protected isEnglishLockCapable: boolean;
  protected fixRootMetadata: Title;
  protected sorting: {
    iterating: string[],
    orders: Array<'asc' | 'desc'>
  } = {
      iterating: ['language'],
      orders: ['asc']
    };

  constructor(
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected productLayoutHelper: ProductLayoutHelper,
    protected notificationService: NotificationService,
  ) {
    super(appConfig);
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.localeLimit = this.appConfig.get('ux.dataAccordion.lockingPopup.localeLimit');
    const moduleInfo: any = modulesPath.titles.details;

    Object.keys(LocalizationSpecific).forEach(
      (section: string) => {
        this.lockPrivileges.set(
          section,
          this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo[section.toLowerCase()].path, [ActionTypeEnum.lock])
        );

        this.unlockPrivileges.set(
          section,
          this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo[section.toLowerCase()].path, [ActionTypeEnum.unlock])
        );
      }
    );

    this.isEnglishLockCapable = this.capabilitiesManager.hasUserPrivilegeOn(
      moduleInfo.english.root.path, [ActionTypeEnum.lock]
    );
  }

  /**
   * Returns the order of the sorting
   * @param sortBy string
   * @returns number
   */
  protected sortingOrder(sortBy: string): number | undefined {
    const collection: any = this.sorting.iterating;
    const index = collection.indexOf(sortBy);
    if (index === -1) {
      return undefined;
    } else {
      return index + 1;
    }
  }

  /**
   * Sorts the collection with the given name and criteria, and returns the result.
   *
   * @param sortBy string
   * @returns any
   */
   protected sortedBy(sortBy: string): string | undefined {
    const collection: any = this.sorting.iterating;
    const index = collection.indexOf(sortBy);
    if (index === -1) {
      return undefined;
    } else {
      return this.sorting.orders[index];
    }
  }

  protected getFilterCriteria(): CallableFunction {
    const criteria: CallableFunction = (record: any) => {
      for (const filter of this.filters.filter) {
        if (record?.language?.name?.toLowerCase()?.includes(filter?.toLowerCase())) {
          return true;
        }

        for (const territory of record.territory) {
          if (territory?.name?.toLowerCase()?.includes(filter?.toLowerCase())) {
            return true;
          }
        }

        for (const product of record.productType) {
          if (product?.name?.toLowerCase()?.includes(filter?.toLowerCase())) {
            return true;
          }
        }

        for (const account of record.account) {
          if (account.name.toLowerCase().includes(filter?.toLowerCase())) {
            return true;
          }
        }

      }
    };

    return criteria;
  }

  protected ensureFiltersExists(): void {
    if (!this.filters) {
      this.filters = {
        filter: [],
        groupBy: 'languageSpecific'
      };
    }
  }

  protected mapLocalizations(): void {
    this.status = this.componentStatuses.fetchingData;

    this.productLayoutHelper.mapAttributes(this.product.localizations as Title[], 'languageSpecific').subscribe(
      (metadata: Title[]) => {
        this.fixRootMetadata = metadata.find(
          titleMetadata => Locale.isEnglishRoot(titleMetadata.locale)
        );

        if (this.fixRootMetadata) {
          metadata = _reject(metadata, this.fixRootMetadata);
        }

        metadata = metadata.filter((data: Title) => data.language && !Language.isAll(data.language));

        this.pager.setRecords(
          _orderBy(
            metadata,
            this.createIterators(),
            this.sorting.orders
          )
        );
        this.unsortedRecords = metadata;

        if ((metadata.length) === 0 && (this.fixRootMetadata === undefined)) {
          this.status = this.componentStatuses.dataNotFound;
        } else {
          this.status = this.componentStatuses.dataFound;
        }

      }, error => {
        this.status = this.componentStatuses.error;
        this.notificationService.handleError('Failed mapping attributes.', error);
      }
    );
  }

  /**
   * Indicates if ti has to display no-data section.
   *
   * @returns boolean
   */
  protected hasDisplayNoData(): boolean {
    const hasIt: boolean = !this.pager.hasRecords() && !this.fixRootMetadata;

    return hasIt;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Sets the given title metadata as selected.
   *
   * @param productMetadata TitleMetadataInterface
   * @returns void
   */
  protected selectMetadata(productMetadata: Title): void {
    this.updateList = false;
    this.selectedProductMetadata = productMetadata;
    this.onSelectMetadata.emit(this.selectedProductMetadata);
    this.updateList = true;
  }

  /**
   * Change the order of the list
   *
   * @param iterate string
   */
  protected sortBy(iterate: string) {
    const position = this.sorting.iterating.indexOf(iterate);
    if (position === -1) {
      this.sorting.iterating.push(iterate);
      this.sorting.orders.push('asc');

    } else if (this.sorting.orders[position] === 'asc') {
      this.sorting.orders[position] = 'desc';

    } else if (this.sorting.orders[position] === 'desc') {
      this.sorting.iterating.splice(position, 1);
      this.sorting.orders.splice(position, 1);

    }

    if (this.unsortedRecords) {
      this.pager.setRecords(this.unsortedRecords);
    }
    if (this.sorting.iterating.length !== 0) {
      this.pager.setRecords(_orderBy(
        this.pager.records,
        this.createIterators(),
        this.sorting.orders
      ));
    }
  }

  /**
   * Create the iterators for the orderBy function
   *
   * @returns any[]
   */
  protected createIterators(): any[] {
    const iterators: any[] = [];
    for (const iterator of this.sorting.iterating) {
      if (iterator === 'language') {
        iterators.push((record: string) => record[iterator]?.toLowerCase());
      } else {
        iterators.push((record: string) => record[iterator][0].name?.toLowerCase());
      }
    }
    return iterators;
  }

  /**
   * Gets the collection size including the fixed root.
   *
   * @return number
   */
  protected getCollectionSize(): number {
    return this.pager.records.length;
  }


  /**
   * Indicates if the given metadata title is an empty localization, according to current group in filters.
   *
   * @param metadata Title
   * @return boolean
   */
  protected isLocalizationEmpty(metadata: Title): boolean {
    return !this.productLayoutHelper.hasGroupAttributes(this.product.type, metadata, this.filters.groupBy, false);
  }

}
